import { useMemo } from 'react'

import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'

import { useAppSelector } from 'sen/redux/toolkit/hooks'

export type UserEmail = string

export interface UseDFPContentConditionParams {
  location: Location
}

export enum UIState {
  'missedEntitlement',
  'renderContent'
}

export default function useDFPContentCondition(): [UIState, UserEmail] {
  const { accessTokenId, userEmail } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    userEmail: _stores.user.data?.email
  }))
  const [accessTokenLib] = useAccessTokenLib()

  const hasDFPEntitlement = useMemo(() => {
    return accessTokenLib.hasDFPEntitlement(accessTokenId)
  }, [accessTokenId, accessTokenLib])

  const uiState: UIState = useMemo(() => {
    switch (true) {
      case !hasDFPEntitlement:
        return UIState.missedEntitlement
      default:
        return UIState.renderContent
    }
  }, [hasDFPEntitlement])

  return useMemo(() => {
    return [uiState, userEmail || '']
  }, [uiState, userEmail])
}
