// TODO: Remove this file as part of BNFIR-3518
import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { FraudUnifiedData, ModifiedFraudUnifiedData } from 'global/types/api/dmarc'
import { isPending } from 'global/redux/toolkit/api'
import { useFormatMessage } from 'global/lib/localization'
import { BASE_IMG_PATH } from 'global/configs/theme/images.config'
import { getCountryName } from 'global/lib/isoCountries'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getDmarcFraudUnifiedData } from 'sen/redux/features/dmarc/dmarcSlice'
import { ChartCountryEmailHeader, getChartCountryEmailHeader } from 'global/components/lib/charts/geoChartHeader'

export interface ChartConfig {
  data: [string, number, any][]
}

const BASE_I18N_KEY = 'sen.app.dmarc_countries_dialog.dmarc_countries_map'

export default function useDmarcCountriesMapLogic(): [boolean, ChartCountryEmailHeader, ChartConfig['data']] {
  const { activePath, getDmarcUnifiedFraudDataInProgress, dmarcUnifiedFraudData } = useAppSelector(_stores => ({
    activePath: _stores.app.activePath,
    getDmarcUnifiedFraudDataInProgress: isPending(_stores.dmarc.getDmarcFraudUnifiedMapDataApiStatus),
    dmarcUnifiedFraudData: _stores.dmarc.dmarcFraudUnifiedMapData
  }))

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    if (!dmarcUnifiedFraudData) {
      dispatch(getDmarcFraudUnifiedData({ domain: activePath.params.domain, mapView: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataWithFlag = useMemo(() => {
    const { data } = process(
      (dmarcUnifiedFraudData || []).map((report: FraudUnifiedData) => ({
        ...(report && {
          ...report,
          flagSrc: `${BASE_IMG_PATH}flags/${report.country.toLowerCase()}.svg`,
          name: getCountryName(report.country)
        })
      })),
      {}
    )
    return data
  }, [dmarcUnifiedFraudData])

  // Geochart
  const chartData = useMemo(() => {
    return dataWithFlag.reduce(
      (
        all: ChartConfig['data'],
        { country, fraudEmailCount, totalEmailCount, flagSrc, name }: ModifiedFraudUnifiedData
      ) => {
        const tooltip = `
          <div style="min-width: 150px">
            <img src="${flagSrc}" width="10" height="10" alt="" />
            <strong>${name}</strong>
            <br />
            ${formatMessage('emails_from_blacklist')}: <strong>${fraudEmailCount}</strong>
            <br />
            ${formatMessage('total_emails')}: <strong>${totalEmailCount}</strong>
          </div>
        `

        return [...all, [country, totalEmailCount, tooltip]]
      },
      []
    ) as ChartConfig['data']
  }, [dataWithFlag, formatMessage])

  return useMemo(() => {
    return [getDmarcUnifiedFraudDataInProgress, getChartCountryEmailHeader(), chartData]
  }, [getDmarcUnifiedFraudDataInProgress, chartData])
}
