import { useMemo, useCallback, useEffect } from 'react'

import { CompanyDomain } from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isSuccess, isPending, getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'dfp/redux/toolkit/hooks'
import { getCompanyDomains, deleteDomain, resetDeletedDomain } from 'dfp/redux/features/dmarc/dmarcSlice'

export interface DeleteDomainDialogLogicProps {
  onClose: () => void
  domain: CompanyDomain
}

export interface DialogConfig {
  isDeleteDomainInProgress: boolean
  isDeleteDomainSuccess: boolean
  deleteDomainError: string | undefined
}

export interface DialogEvents {
  onDelete: () => void
  onClose: () => void
}

export default function useDeleteDomainDialogLogic({
  onClose,
  domain
}: DeleteDomainDialogLogicProps): [DialogConfig, DialogEvents] {
  const { accessTokenId, isDeleteDomainInProgress, isDeleteDomainSuccess, deleteDomainError } = useAppSelector(
    _stores => ({
      accessTokenId: _stores.accessToken?.accessToken?.id,
      isDeleteDomainInProgress: isPending(_stores.dmarc.deleteDomainApiStatus),
      isDeleteDomainSuccess: isSuccess(_stores.dmarc.deleteDomainApiStatus),
      deleteDomainError: getErrorMessage(_stores.dmarc.deleteDomainApiStatus)
    })
  )
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    return () => {
      dispatch(resetDeletedDomain())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isDeleteDomainSuccess) {
      dispatch(getCompanyDomains())
    }
  }, [isDeleteDomainSuccess, dispatch])

  const onDelete = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DELETE_DOMAIN_CONFIRMED, {
      accessTokenId,
      domain: domain.name
    })

    dispatch(deleteDomain(domain.name))
  }, [accessTokenId, domain, dispatch])

  const onCloseDialog = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DELETE_DOMAIN_CANCEL, {
      accessTokenId,
      domain: domain.name
    })

    onClose()
  }, [onClose, accessTokenId, domain])

  return useMemo(() => {
    return [
      {
        isDeleteDomainInProgress,
        isDeleteDomainSuccess,
        deleteDomainError
      },
      {
        onDelete,
        onClose: onCloseDialog
      }
    ]
  }, [isDeleteDomainInProgress, isDeleteDomainSuccess, deleteDomainError, onDelete, onCloseDialog])
}
