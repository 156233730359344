import React, { useMemo } from 'react'

import { Button, DialogActions } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import { StepProps } from 'dfp/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'
import ConfigureStepContent from 'dfp/components/lib/dialogs/domainWizardDialog/steps/ConfigureStepContent'

const BASE_I18N_KEY = 'dfp.app.domain_wizard_dialog.dmarc_setup'

export const DomainWizardStepSpfDmarcSetup: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <>
        <ConfigureStepContent config={config} title={formatMessage('title')} domainValues={config.dmarcDomainValues} />
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('check_my_dmarc')}
          </Button>
        </DialogActions>
      </>
    ),
    [formatMessage, events, config]
  )
}

export default DomainWizardStepSpfDmarcSetup
