import { config } from 'global/lib/config'

const { BUNDLES } = config

const BUNDLE2_ACCESS = [BUNDLES.BUNDLE2]
const BUNDLE1_ACCESS = [BUNDLES.BUNDLE1, BUNDLES.BUNDLE2]
const DP_ACCESS = [BUNDLES.BUNDLE2]

const UI_ROUTES = {
  signinSignup: {
    SIGNIN_SIGNUP: {
      path: '/signup'
    },
    SIGNIN_CONNECT: {
      path: '/signin/connect'
    },
    SIGNIN_REMEDIATES: {
      path: '/signin/remediates'
    },
    SIGNUP_PROFILE: {
      path: '/signup/profile'
    },
    SIGNUP_POLICY: {
      path: '/signup/policy'
    },
    SIGNUP_COMPLETE: {
      path: '/signup/complete'
    },
    SIGNUP_COMPLETE_POLICY: {
      path: '/signup/complete/policy'
    },
    SIGNUP_REMEDIATES: {
      path: '/signup/remediates'
    }
  },
  FEEDBACK: {
    path: '/feedback/:id'
  },
  dashboard: {
    DASHBOARD: {
      path: '/report/:reportId/dashboard',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE1_ACCESS
      }
    },
    DASHBOARD_REPORT: {
      path: '/report/:reportId/dashboard/reports',
      metadata: {
        isReportRoute: true,
        sideMenu: 'DASHBOARD',
        bundle: BUNDLE1_ACCESS
      }
    },
    EMPTY_REPORT: {
      path: '/report',
      metadata: {
        isReportRoute: true,
        sideMenu: 'DASHBOARD',
        bundle: BUNDLE1_ACCESS
      }
    }
  },
  settings: {
    SETTINGS: {
      path: '/report/:reportId/settings',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE1_ACCESS
      }
    }
  },
  accountTakeover: {
    ACCOUNT_TAKEOVER: {
      path: '/report/:reportId/account-takeover',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE1_ACCESS
      }
    },
    ACCOUNT_TAKEOVER_INCIDENTS: {
      path: '/report/:reportId/account-takeover/incidents',
      metadata: {
        isReportRoute: true,
        sideMenu: 'ACCOUNT_TAKEOVER',
        bundle: BUNDLE1_ACCESS
      }
    },
    ACCOUNT_TAKEOVER_INCIDENT: {
      path: '/report/:reportId/account-takeover/incidents/:incidentId',
      metadata: {
        isReportRoute: true,
        sideMenu: 'ACCOUNT_TAKEOVER',
        accountSwitchTargetId: 'ACCOUNT_TAKEOVER_INCIDENTS',
        bundle: BUNDLE1_ACCESS
      }
    },
    ACCOUNT_TAKEOVER_INCIDENT_EXTERNAL_RECIPIENTS: {
      path: '/report/:reportId/account-takeover/incidents/:incidentId/external-recipients',
      metadata: {
        isReportRoute: true,
        sideMenu: 'ACCOUNT_TAKEOVER',
        accountSwitchTargetId: 'ACCOUNT_TAKEOVER_INCIDENTS',
        bundle: BUNDLE1_ACCESS
      }
    },
    ACCOUNT_TAKEOVER_INBOX_RULES: {
      path: '/report/:reportId/account-takeover/rules',
      metadata: {
        isReportRoute: true,
        sideMenu: 'ACCOUNT_TAKEOVER',
        bundle: BUNDLE1_ACCESS
      }
    },
    ACCOUNT_TAKEOVER_SIGN_INS: {
      path: '/report/:reportId/account-takeover/sign-ins',
      metadata: {
        isReportRoute: true,
        sideMenu: 'ACCOUNT_TAKEOVER',
        bundle: BUNDLE1_ACCESS
      }
    }
  },
  domainFraud: {
    DOMAIN_FRAUD: {
      path: '/report/:reportId/domain-fraud',
      metadata: {
        isReportRoute: true,
        bundle: DP_ACCESS
      }
    },
    DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB: {
      path: '/report/:reportId/domain-fraud/dmarc-review/:domain/unknown',
      metadata: {
        isReportRoute: true,
        accountSwitchTargetId: 'DOMAIN_FRAUD',
        sideMenu: 'DOMAIN_FRAUD',
        bundle: DP_ACCESS
      }
    },
    DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB: {
      path: '/report/:reportId/domain-fraud/dmarc-review/:domain/approved',
      metadata: {
        isReportRoute: true,
        accountSwitchTargetId: 'DOMAIN_FRAUD',
        sideMenu: 'DOMAIN_FRAUD',
        bundle: DP_ACCESS
      }
    },
    DOMAIN_FRAUD_DMARC_REVIEW_WITH_REJECTED_TAB: {
      path: '/report/:reportId/domain-fraud/dmarc-review/:domain/rejected',
      metadata: {
        isReportRoute: true,
        accountSwitchTargetId: 'DOMAIN_FRAUD',
        sideMenu: 'DOMAIN_FRAUD',
        bundle: DP_ACCESS
      }
    }
  },
  cleanup: {
    CLEANUP: {
      path: '/cleanup',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE2_ACCESS
      }
    },
    CLEANUP_TABLE: {
      path: '/cleanup/:reportId',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE2_ACCESS
      }
    }
  },
  demo: {
    DEMO_REPORT: {
      path: '/demo'
    }
  },
  startTrial: {
    START_TRIAL: {
      path: '/start-trial'
    }
  },
  admin: {
    ADMIN: {
      path: '/admin'
    }
  },
  license: {
    LICENSE: {
      path: '/report/:reportId/licenses',
      metadata: {
        isReportRoute: true,
        bundle: BUNDLE1_ACCESS
      }
    }
  },
  unifiedReporting: {
    UNIFIED_REPORTING_ROOT: {
      path: '/report/:reportId/unified-reporting',
      metadata: {
        bundle: BUNDLE1_ACCESS,
        isReportRoute: true
      }
    },
    REPORT_LIST: {
      path: '/report/:reportId/unified-reporting/report',
      metadata: {
        bundle: BUNDLE1_ACCESS,
        isReportRoute: true
      }
    },

    REPORT_LIST_REPORT: {
      path: '/report/:reportId/unified-reporting/report/:urReportId',
      metadata: {
        bundle: BUNDLE1_ACCESS,
        isReportRoute: true
      }
    }
  },
  scheduledReports: {
    SCHEDULED_REPORTS: {
      path: '/report/:reportId/unified-reporting/scheduled-reports',
      metadata: {
        bundle: BUNDLE1_ACCESS,
        isReportRoute: true,
        sideMenu: 'SCHEDULED_REPORTS'
      }
    }
  }
}

const REDIRECT_UI_ROUTES = {
  ACCOUNT_TAKEOVER_CASB_INCIDENT_ROOT: {
    path: '/report/:reportId/account-takeover/incidents/incident'
  },
  ACCOUNT_TAKEOVER_CASB_INCIDENT: {
    path: '/report/:reportId/account-takeover/incidents/incident/:incidentId'
  },
  ACCOUNT_TAKEOVER_CASB_INCIDENT_EXTERNAL_RECIPIENTS: {
    path: '/report/:reportId/account-takeover/incidents/incident/:incidentId/external-recipients'
  },
  DOMAIN_FRAUD_DMARC_REVIEW_ROOT: {
    path: '/report/:reportId/domain-fraud/dmarc-review'
  },
  DOMAIN_FRAUD_DMARC_REVIEW_WITHOUT_TAB: {
    path: '/report/:reportId/domain-fraud/dmarc-review/:domain'
  },
  DASHBOARD_ROOT: {
    path: '/report/:reportId'
  }
}

export { REDIRECT_UI_ROUTES }

export default UI_ROUTES
