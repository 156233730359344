import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'

import { DataTableState } from 'dfp/redux/types/dataTables'

import * as rufDmarcSamples from 'dfp/redux/features/dataTables/dmarcSamples/ruf'
import * as dmarcFraudUnifiedData from 'dfp/redux/features/dataTables/dmarcFraudUnifiedData/dmarcFraudUnifiedDataSlice'
import * as dmarcFraudUnifiedRufData from 'dfp/redux/features/dataTables/dmarcFraudUnifiedRufData/dmarcFraudUnifiedRufDataSlice'
import * as dmarcAllApproved from 'dfp/redux/features/dataTables/dmarcSources/dmarcAllApproved'
import * as dmarcAllRejected from 'dfp/redux/features/dataTables/dmarcSources/dmarcAllRejected'
import * as dmarcHighVolumesSources from 'dfp/redux/features/dataTables/dmarcSources/dmarcHighVolumeSources'
import * as dmarcIpsFailed from 'dfp/redux/features/dataTables/dmarcIps/failedIps'
import * as dmarcIpsPassed from 'dfp/redux/features/dataTables/dmarcIps/passedIps'
import * as dmarcLowVolumeSources from 'dfp/redux/features/dataTables/dmarcSources/dmarcLowVolumeSources'
import * as dmarcFailedSubdomains from 'dfp/redux/features/dataTables/dmarcSubdomains/failedSubdomains'
import * as dmarcPassedSubdomains from 'dfp/redux/features/dataTables/dmarcSubdomains/passedSubdomains'
import * as domains from 'dfp/redux/features/dataTables/domains/domainsSlice'

export interface DataTablesState {
  dmarcFraudUnifiedData: DataTableState
  dmarcFraudUnifiedRufData: DataTableState
  dmarcIps: {
    failed: DataTableState
    passed: DataTableState
  }
  dmarcSamples: {
    ruf: DataTableState
  }
  dmarcSources: {
    allApproved: DataTableState
    allRejected: DataTableState
    highVolume: DataTableState
    lowVolume: DataTableState
  }
  dmarcSubdomains: {
    failed: DataTableState
    passed: DataTableState
  }
  domains: DataTableState
}

// initialState
export const INITIAL_STATE: DataTablesState = {
  dmarcFraudUnifiedData: dmarcFraudUnifiedData.INITIAL_STATE,
  dmarcFraudUnifiedRufData: dmarcFraudUnifiedRufData.INITIAL_STATE,
  dmarcIps: {
    failed: dmarcIpsFailed.INITIAL_STATE,
    passed: dmarcIpsPassed.INITIAL_STATE
  },
  dmarcSamples: {
    ruf: rufDmarcSamples.INITIAL_STATE
  },
  dmarcSources: {
    allApproved: dmarcAllApproved.INITIAL_STATE,
    allRejected: dmarcAllRejected.INITIAL_STATE,
    highVolume: dmarcHighVolumesSources.INITIAL_STATE,
    lowVolume: dmarcLowVolumeSources.INITIAL_STATE
  },
  dmarcSubdomains: {
    failed: dmarcFailedSubdomains.INITIAL_STATE,
    passed: dmarcPassedSubdomains.INITIAL_STATE
  },
  domains: domains.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetDataTables.toString(), () => {
      return {
        ...INITIAL_STATE
      }
    })
    builder
      .addMatcher(isAnyOf(dmarcFraudUnifiedData.update, dmarcFraudUnifiedData.reset), (state, action) => {
        state.dmarcFraudUnifiedData = { ...dmarcFraudUnifiedData.default(state.dmarcFraudUnifiedData, action) }
      })
      .addMatcher(isAnyOf(dmarcFraudUnifiedRufData.update, dmarcFraudUnifiedRufData.reset), (state, action) => {
        state.dmarcFraudUnifiedRufData = { ...dmarcFraudUnifiedRufData.default(state.dmarcFraudUnifiedRufData, action) }
      })
      .addMatcher(isAnyOf(dmarcIpsFailed.update, dmarcIpsFailed.reset), (state, action) => {
        state.dmarcIps.failed = { ...dmarcIpsFailed.default(state.dmarcIps.failed, action) }
      })
      .addMatcher(isAnyOf(dmarcIpsPassed.update, dmarcIpsPassed.reset), (state, action) => {
        state.dmarcIps.passed = { ...dmarcIpsPassed.default(state.dmarcIps.passed, action) }
      })
      .addMatcher(isAnyOf(rufDmarcSamples.update, rufDmarcSamples.reset), (state, action) => {
        state.dmarcSamples.ruf = { ...rufDmarcSamples.default(state.dmarcSamples.ruf, action) }
      })
      .addMatcher(isAnyOf(dmarcAllApproved.update, dmarcAllApproved.reset), (state, action) => {
        state.dmarcSources.allApproved = { ...dmarcAllApproved.default(state.dmarcSources.allApproved, action) }
      })
      .addMatcher(isAnyOf(dmarcAllRejected.update, dmarcAllRejected.reset), (state, action) => {
        state.dmarcSources.allRejected = { ...dmarcAllRejected.default(state.dmarcSources.allRejected, action) }
      })
      .addMatcher(isAnyOf(dmarcHighVolumesSources.update, dmarcHighVolumesSources.reset), (state, action) => {
        state.dmarcSources.highVolume = { ...dmarcHighVolumesSources.default(state.dmarcSources.highVolume, action) }
      })
      .addMatcher(isAnyOf(dmarcLowVolumeSources.update, dmarcLowVolumeSources.reset), (state, action) => {
        state.dmarcSources.lowVolume = { ...dmarcLowVolumeSources.default(state.dmarcSources.lowVolume, action) }
      })
      .addMatcher(isAnyOf(dmarcFailedSubdomains.update, dmarcFailedSubdomains.reset), (state, action) => {
        state.dmarcSubdomains.failed = { ...dmarcFailedSubdomains.default(state.dmarcSubdomains.failed, action) }
      })
      .addMatcher(isAnyOf(dmarcPassedSubdomains.update, dmarcPassedSubdomains.reset), (state, action) => {
        state.dmarcSubdomains.passed = { ...dmarcPassedSubdomains.default(state.dmarcSubdomains.passed, action) }
      })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
