// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, DialogTitle, DialogContent, DialogActions, Grid } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.enforce_dmarc_dialog'

export const DmarcEnforcementStepInit: React.FC<StepProps> = ({ events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogTitle data-step-name="dmarc-check">
          <Typography className={classes.dialogTitle}>{formatMessage('dmarc_check_title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <LinearProgress className={classes.linearProgress} />
            <Typography className={classes.dialogText}>{formatMessage('dmarc_check_subtitle_1')}</Typography>
            <Typography className={classes.dialogText}>{formatMessage('dmarc_check_subtitle_2')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" disabled onClick={events.onSetStepToInit}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onCloseDialog}>
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events]
  )
}

DmarcEnforcementStepInit.propTypes = {
  config: PropTypes.any.isRequired,
  events: PropTypes.any.isRequired
}

export default DmarcEnforcementStepInit
