// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import { Typography, DialogContent, Grid, Link } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import { config as globalConfig } from 'global/lib/config'

import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'
import DomainValues, {
  DomainValues as DomainValuesType
} from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/DomainValues'

export interface ConfigureContentProps {
  config: StepProps['config']
  title: string
  domainValues: DomainValuesType
  withSubtitleLabel?: boolean
  isSpf?: boolean
  children?: any
}

const BASE_I18N_KEY = 'sen.app.domain_wizard_dialog.configure_content'

export const ConfigureStepContent: React.FC<ConfigureContentProps> = ({
  config,
  title,
  domainValues,
  withSubtitleLabel = false,
  isSpf = false,
  children
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const contentPrefix = isSpf ? 'spf' : 'dmarc'

  return useMemo(
    () => (
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column">
          <Typography className={classes.dialogBiggerText}>{title}</Typography>
          {withSubtitleLabel && (
            <Typography className={classes.dialogText}>{formatMessage(`${contentPrefix}_subtitle_1`)}</Typography>
          )}
          <Typography className={classes.dialogText}>
            1.&nbsp;
            {formatMessage('step_1', {
              link: (txt: string) => (
                <Link key="text" target="_blank" href={config.getRegistrarUrlForDomain()}>
                  {txt}
                </Link>
              )
            })}
          </Typography>
          <Typography className={classes.dialogText}>
            2.&nbsp;
            {formatMessage(`${contentPrefix}_step_2`, {
              b: (txt: string) => <b key={txt}>{txt}</b>
            })}
          </Typography>
          <DomainValues domainValues={domainValues} withTitle={false} />
          {!children && (
            <Typography className={classes.dialogText}>
              {formatMessage('subtitle_2', {
                b: (txt: string) => <b key={txt}>{txt}</b>,
                link: (txt: string) => (
                  <Link key="text" target="_blank" href={globalConfig.LINKS.MICROSOFT_ARTICLE_SPF_RECORD}>
                    {txt}
                  </Link>
                )
              })}
            </Typography>
          )}
          {children}
        </Grid>
      </DialogContent>
    ),
    [classes, formatMessage, config, withSubtitleLabel, title, contentPrefix, domainValues, children]
  )
}

export default ConfigureStepContent
