/**
 * Formerly known as reportResultsService on CASB
 *
 * Functions not included:
 *  getResultsByToken
 *  sentineBootstrappingIsInProgress
 * */
import { orderBy } from 'lodash'

import { AccessToken, AccessTokenSettings } from 'global/types/api/accessTokenType'

import { reduxStore } from 'global/lib/reduxStore'

import { config } from 'global/lib/config'
import featureLib from 'global/lib/feature/feature'
import productLib from 'global/lib/product/product'
import userDataLib from 'global/lib/userData/userData'
import { Products } from 'global/types/productsType'

export function generateAccessTokenLib(accessTokenStore: () => any = () => reduxStore.getState().accessToken) {
  /* ***************** *
   * Boolean functions *
   * ***************** */
  function hasAccessToken(accessTokenId: string): boolean {
    return !!getAccessToken(accessTokenId)
  }

  function hasDFPEntitlement(accessTokenId: string): boolean | null {
    const account = userDataLib.getAccountByAccessToken(accessTokenId)
    return account && account?.dfpEntitled
  }

  function hasForensicsEntitlement(accessTokenId: string): boolean | null {
    const account = userDataLib.getAccountByAccessToken(accessTokenId)
    return account && account.firEntitled
  }

  function hasSentinelEntitlement(accessTokenId: string): boolean | null {
    const account = userDataLib.getAccountByAccessToken(accessTokenId)
    return account && account.sentinelEntitled
  }

  /**
   * Checks if report is being viewed through guest access (share secret)
   * */
  function hasShareSecret(): boolean {
    const accessToken = accessTokenStore()
    return !!accessToken && !!accessToken.shareSecret
  }

  function isAccessTokenSecretActive(accessTokenId: string): boolean {
    const token = getAccessToken(accessTokenId)
    if (token) {
      return token.reportSecret && token.reportSecret.active
    }
    return false
  }

  function isGuestAccess(): boolean {
    const accessToken = accessTokenStore()
    return !!accessToken && !!accessToken.guestAccess
  }

  /* ********************* *
   * End boolean functions *
   * ********************* */

  function getAccessToken(accessTokenId: string): AccessToken | undefined {
    if (isGuestAccess()) {
      return (accessTokenStore() || {}).guestAccessToken
    }
    return userDataLib.getAccessTokenById(accessTokenId)
  }

  function getActiveAccessToken(): AccessToken | undefined {
    return (accessTokenStore() || {}).accessToken
  }

  function getActiveAccessTokenId(): string | undefined {
    return (getActiveAccessToken() || {}).id
  }

  /**
   * Get list of all access tokens sorted by account type (Sentinel comes first) and by name
   * */
  // eslint-disable-next-line no-empty-pattern
  function getAllAccessTokens(requirements = { hideProductTokens: [] as Products[] }): AccessToken[] {
    const accessTokens = orderBy(
      Object.values(userDataLib.getAccessTokens()),
      [
        item => {
          return !featureLib.hasSentinelFeature(item.id)
        },
        'name',
        'created'
      ],
      ['asc', 'asc', 'desc']
    )

    // used by FIR to hide unnecessary tokens since there's no way to link an ETS token to FIR
    if (requirements.hideProductTokens.length) {
      return accessTokens.filter((token: AccessToken) => {
        return !token.products.some((product: Products): boolean => requirements.hideProductTokens.includes(product))
      })
    }

    return accessTokens
  }

  /**
   * Get list of all Sentinel access tokens that have a valid serialnumber
   * */
  function getValidSentinelAccessTokens(): AccessToken[] {
    const accessTokens = Object.values(userDataLib.getAccessTokens()).filter((accessToken: AccessToken) => {
      return (
        productLib.hasSentinelProduct(accessToken.id) &&
        accessToken.serialNumbers?.sentinel?.serialNumber &&
        !['C', 'D'].includes(accessToken.serialNumbers?.sentinel?.state)
      )
    })

    return accessTokens.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
  }

  function getAccessTokenSecretExpires(accessTokenId: string): string | undefined {
    const token = getAccessToken(accessTokenId)
    if (token && token.reportSecret) {
      return token.reportSecret.expires
    }
    return undefined
  }

  function getAccessTokenSecretId(accessTokenId: string): string | undefined {
    const token = getAccessToken(accessTokenId)
    if (token && token.reportSecret) {
      return token.reportSecret.value
    }
    return undefined
  }

  function getAccessTokenShareLink(accessTokenId: string): string {
    return `${config.shareReportURIPrefix}${getAccessTokenSecretId(accessTokenId)}`
  }

  /**
   * Get list of all Sentinel access tokens associated with the accountId.
   * Return first o365 Sentinel token, falls back to first Sentinel token in list
   */
  function getAccountProductToken(accessTokenId: string, product: string): AccessToken | undefined {
    const account = userDataLib.getAccountByAccessToken(accessTokenId)
    if (account) {
      const productTokens =
        product === config.PRODUCTS.SENTINEL
          ? account.accessTokens.filter(item => featureLib.hasSentinelFeature(item.id))
          : account.accessTokens.filter(item => featureLib.hasForensicsFeature(item.id))
      const o365Token = productTokens.find(token => token.provider === config.CLOUD_PROVIDERS.office365.id)
      if (o365Token) {
        return o365Token
      }
      return productTokens[0]
    }
    return undefined
  }

  function getCurrentBccAccount(): string | undefined {
    return (accessTokenStore() || {}).bccAccount
  }

  function getCurrentEssAccount(): string | undefined {
    return (accessTokenStore() || {}).essAccount
  }

  function getCurrentSettings(): AccessTokenSettings | undefined {
    return (accessTokenStore() || {}).accessToken.settings
  }

  function getDefaultAccessTokenId(): string | null {
    const accessTokens = getAllAccessTokens()
    if (accessTokens && accessTokens.length > 0) {
      return accessTokens[0].id
    }
    return null
  }

  /**
   * Get default access token (check default bcc account first)
   * Falls back to getDefaultAccessTokenId()
   */
  function getDefaultBccAccountAccessTokenId(bccId = '', product = config.PRODUCTS.SENTINEL): string | null {
    if (!bccId) {
      // eslint-disable-next-line no-param-reassign
      bccId = userDataLib.getUser().defaultAccountBccId
    }

    const defaultBccAccount = userDataLib.getAccountByBccId(bccId)

    if (defaultBccAccount && defaultBccAccount.accessTokens && defaultBccAccount.accessTokens.length > 0) {
      if (product === config.PRODUCTS.SENTINEL) {
        const productToken: AccessToken | undefined =
          getAccountProductToken(defaultBccAccount.accessTokens[0].id, product) ||
          getAccountProductToken(defaultBccAccount.accessTokens[0].id, config.PRODUCTS.FORENSICS) ||
          defaultBccAccount.accessTokens[0]

        return productToken.id
      }
      const productToken: AccessToken | undefined =
        getAccountProductToken(defaultBccAccount.accessTokens[0].id, product) ||
        getAccountProductToken(defaultBccAccount.accessTokens[0].id, config.PRODUCTS.SENTINEL) ||
        defaultBccAccount.accessTokens[0]

      return productToken.id
    }

    return getDefaultAccessTokenId()
  }

  function getDefaultFirAccessTokenId(includeLimitedAccessTokens = true): string | null {
    const accessTokens: AccessToken[] = (getAllAccessTokens() || []).filter((token: AccessToken) => {
      if (includeLimitedAccessTokens && productLib.hasLimitedForensicsAccess(token.id)) {
        return false
      }
      return token.products.includes(config.PRODUCTS.FORENSICS)
    })
    if (accessTokens.length > 0) {
      return accessTokens[0].id
    }
    return getDefaultBccAccountAccessTokenId()
  }

  function getDefaultDfpAccessTokenId(includeLimitedAccessTokens = true): string | null {
    const accessTokens: AccessToken[] = (getAllAccessTokens() || []).filter((token: AccessToken) => {
      return token.products.includes(config.PRODUCTS.DFP)
    })

    if (accessTokens.length > 0) {
      return accessTokens[0].id
    }

    return getDefaultBccAccountAccessTokenId()
  }

  function getAccessTokenFromUrl(): string | undefined {
    const accessTokenMatch: RegExpMatchArray | null = window.location.pathname.match(config.VALIDATORS.ACCESS_TOKEN)
    return (accessTokenMatch && accessTokenMatch[0]) || undefined
  }

  /**
   * Get share secret (in guest access mode)
   * */
  function getShareSecret(): string | undefined {
    return (accessTokenStore() || {}).shareSecret?.value
  }

  return {
    // boolean methods
    hasAccessToken,
    hasDFPEntitlement,
    hasForensicsEntitlement,
    hasSentinelEntitlement,
    hasShareSecret,
    isAccessTokenSecretActive,
    isGuestAccess,

    // getter methods
    getAccessToken,
    getActiveAccessToken,
    getActiveAccessTokenId,
    getAllAccessTokens,
    getAccessTokenSecretExpires,
    getAccessTokenSecretId,
    getAccessTokenShareLink,
    getAccountProductToken,
    getCurrentBccAccount,
    getCurrentEssAccount,
    getCurrentSettings,
    getDefaultAccessTokenId,
    getDefaultBccAccountAccessTokenId,
    getDefaultDfpAccessTokenId,
    getDefaultFirAccessTokenId,
    getAccessTokenFromUrl,
    getShareSecret,
    getValidSentinelAccessTokens
  }
}

export default generateAccessTokenLib()
