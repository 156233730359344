import accessTokenLib from 'global/lib/accessToken/accessToken'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { logout, setError } from 'global/redux/features/auth/authSlice'
import { config } from 'global/lib/config'
import productLib from 'global/lib/product/product'
import { User } from 'global/types/api/userType'

import routesConfig from 'dfp/lib/routes/routesConfig'
import { setUser } from 'dfp/redux/features/user/userSlice'

export interface GotoReportPageParams {
  user?: User
  accessTokenId?: string
}

export default function gotoReportPage({ user, accessTokenId }: GotoReportPageParams): void {
  if (user) reduxStore.dispatch(setUser(user))

  const validatedAccessTokenId =
    accessTokenId ||
    accessTokenLib.getDefaultDfpAccessTokenId() ||
    accessTokenLib.getDefaultBccAccountAccessTokenId(user?.defaultAccountBccId, config.PRODUCTS.DFP) ||
    accessTokenLib.getAllAccessTokens()[0]?.id

  if (validatedAccessTokenId) {
    if (productLib.hasDFPProduct(validatedAccessTokenId)) {
      reduxStore
        .dispatch(setCurrentAccessToken(validatedAccessTokenId))
        .unwrap()
        .then(() => {
          routesConfig.DOMAIN_FRAUD.goto({ reportId: validatedAccessTokenId })
        })
        .catch(() => {
          reduxStore.dispatch(logout())
          reduxStore.dispatch(setError('no_permissions'))
        })
    } else {
      routesConfig.START_TRIAL.goto()
    }
  } else {
    routesConfig.SIGNIN_CONNECT.goto()
  }
}
