import { useMemo } from 'react'

import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useProductLib from 'global/lib/product/useProduct'

import { useAppSelector } from 'dfp/redux/toolkit/hooks'

export type UserEmail = string

export interface UseDFPContentConditionParams {
  location: Location
}

export enum UIState {
  'loading',
  'missingBccEntitlement',
  'renderContent',
  'startTrial'
}

export default function useDFPContentCondition(): [UIState, UserEmail] {
  const { accessTokenId, userEmail } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    userEmail: _stores.user.data?.email
  }))

  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()

  const hasDFPEntitlement = useMemo(() => {
    return accessTokenLib.hasDFPEntitlement(accessTokenId)
  }, [accessTokenId, accessTokenLib])

  const hasDFPProduct = useMemo(() => {
    return productLib.hasDFPProduct(accessTokenId)
  }, [productLib, accessTokenId])

  const uiState: UIState = useMemo(() => {
    switch (true) {
      case !hasDFPProduct:
        return UIState.startTrial
      case !hasDFPEntitlement:
        return UIState.missingBccEntitlement
      default:
        return UIState.renderContent
    }
  }, [hasDFPEntitlement, hasDFPProduct])

  return useMemo(() => {
    return [uiState, userEmail || '']
  }, [uiState, userEmail])
}
