// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    ...defaultTableStyles,
    loading: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: CELL_HEIGHT * 5 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT,
      '& td': {
        fontSize: '13px',
        lineHeight: 'normal'
      }
    },
    gridLayout: {
      '& .k-grid-table': {
        wordBreak: 'break-word'
      },
      '& .k-grid-header col:nth-of-type(1)': {
        width: '15%'
      },
      '& .k-grid-table col:nth-of-type(1)': {
        width: '15%'
      },
      '& .k-grid-header col:nth-of-type(2)': {
        width: '40%'
      },
      '& .k-grid-table col:nth-of-type(2)': {
        width: '40%'
      },
      '& .k-grid-header col:nth-of-type(3)': {
        width: '15%'
      },
      '& .k-grid-table col:nth-of-type(3)': {
        width: '15%'
      },
      '& .k-grid-header col:nth-of-type(4)': {
        width: '15%'
      },
      '& .k-grid-table col:nth-of-type(4)': {
        width: '15%'
      },
      '& .k-grid-header col:nth-of-type(5)': {
        width: '15%'
      },
      '& .k-grid-table col:nth-of-type(5)': {
        width: '15%'
      }
    }
  }
})
