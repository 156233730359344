// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Zoom,
  Alert,
  Typography,
  Grid,
  Tabs,
  Tab
} from '@barracuda-internal/bds-core'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import DEFAULTS from 'global/configs/theme/defaults'

import useInvestigateDialogLogic from 'sen/components/lib/dialogs/investigateDmarc/useInvestigateDmarcDialogLogic'
import styles from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogStyles'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import ViewBySendingDomain from 'sen/components/lib/dialogs/investigateDmarc/views/ViewBySendingDomain'
import ViewBySendingIPs from 'sen/components/lib/dialogs/investigateDmarc/views/ViewBySendingIPs'
import RufSamples from 'sen/components/lib/dialogs/investigateDmarc/views/RufSamples'

const BASE_I18N_KEY = 'sen.app.investigate_dmarc_dialog'

export const InvestigateDmarcDialog: React.FC<T.InvestigateDmarcDialogProps> = ({ onClose, dmarcSource, domain }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const classes = styles()

  const [logicConfig, eventHandlers] = useInvestigateDialogLogic({ dmarcSource, domain, onClose })

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="lg"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-field="investigate-dialog"
      >
        <DialogTitle>
          {logicConfig.error && (
            <Alert className={classes.alert} severity="error" data-field="investigate-dialog-alert">
              {errorFormatMessage(logicConfig.error)}
            </Alert>
          )}

          <Typography className={classes.dialogTitle}>
            {formatMessage('title', {
              count: <b key={uuidv4()}>{dmarcSource.emailCount.toLocaleString()}</b>,
              host: (
                <b key={uuidv4()}>
                  {dmarcSource.product && (
                    <>
                      {dmarcSource.product.name}&nbsp;({dmarcSource.host})
                    </>
                  )}
                  {!dmarcSource.product && <>{dmarcSource.host}</>}
                </b>
              )
            })}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {!logicConfig.isLoaded && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}

          {logicConfig.isLoaded &&
            [T.Views.viewBySendingips, T.Views.viewBySendingdomain].includes(logicConfig.selectedView) && (
              <Grid container direction="column">
                <Grid className={classes.buttonContainer} container justifyContent="flex-end" direction="row">
                  <Button
                    color="primary"
                    variant="text"
                    size="large"
                    disabled={!dmarcSource.hasRuf}
                    onClick={() => eventHandlers.onChangeView(T.Views.rufSamples)}
                    data-field="ruf-samples-button"
                  >
                    &nbsp;&nbsp;{formatMessage(dmarcSource.hasRuf ? 'view_failure_samples' : 'no_failure_samples')}
                    &nbsp;&nbsp;
                  </Button>{' '}
                  <Button
                    color="primary"
                    variant="text"
                    size="large"
                    onClick={() =>
                      eventHandlers.onChangeView(
                        logicConfig.selectedView === T.Views.viewBySendingdomain
                          ? T.Views.viewBySendingips
                          : T.Views.viewBySendingdomain
                      )
                    }
                  >
                    &nbsp;&nbsp;
                    {formatMessage(
                      logicConfig.selectedView === T.Views.viewBySendingdomain
                        ? 'view_by_sending_ips'
                        : 'view_by_sending_domain'
                    )}
                    &nbsp;&nbsp;
                  </Button>
                </Grid>

                <Tabs
                  className={classes.tabs}
                  value={logicConfig.selectedTab}
                  onChange={eventHandlers.onChangeTab}
                  classes={{
                    indicator: classes.indicator
                  }}
                >
                  {[T.Tabs.fail, T.Tabs.pass].map((tab: string, index: number) => (
                    <Tab
                      label={
                        <Grid container direction="column">
                          <Typography align="left">
                            {`${formatMessage(tab)} (${logicConfig.tabPercentages[index]})`}
                          </Typography>
                        </Grid>
                      }
                      value={tab}
                      key={tab}
                    />
                  ))}
                </Tabs>
              </Grid>
            )}
        </DialogContent>

        {logicConfig.isLoaded && (
          <>
            {logicConfig.selectedView === T.Views.viewBySendingdomain && (
              <ViewBySendingDomain
                domain={domain}
                dmarcSource={dmarcSource}
                onClose={eventHandlers.onCloseDialog}
                selectedTab={logicConfig.selectedTab}
              />
            )}

            {logicConfig.selectedView === T.Views.viewBySendingips && (
              <ViewBySendingIPs
                domain={domain}
                dmarcSource={dmarcSource}
                onClose={eventHandlers.onCloseDialog}
                selectedTab={logicConfig.selectedTab}
              />
            )}

            {logicConfig.selectedView === T.Views.rufSamples && (
              <RufSamples
                domain={domain}
                dmarcSource={dmarcSource}
                onBack={eventHandlers.onChangeViewBack}
                onClose={eventHandlers.onCloseDialog}
              />
            )}
          </>
        )}

        {logicConfig.isLoaded && logicConfig.error && (
          <DialogActions>
            <Button color="primary" variant="contained" size="large" onClick={onClose}>
              &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        )}
      </Dialog>
    ),
    [classes, errorFormatMessage, logicConfig, dmarcSource, formatMessage, eventHandlers, domain, onClose]
  )
}

export default InvestigateDmarcDialog
