import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5)
  },
  card: {
    align: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '55%'
  },
  header: {
    fontsize: theme.font.size.xlarge,
    fontWeight: theme.font.weight.regular
  }
}))
