// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    alert: {
      marginBottom: theme.spacing(2)
    },
    domainValuesWrapper: {
      marginTop: theme.spacing(1)
    },
    copyText: {
      ...defaultDialogStyles.dialogText,
      cursor: 'pointer'
    },
    copiedText: {
      ...defaultDialogStyles.dialogSmallText,
      marginTop: 0
    },
    dmarcRecord: {
      padding: theme.spacing(2),
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    stepper: {
      position: 'absolute',
      right: 0
    }
  }
})
