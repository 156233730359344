import React from 'react'

import GlobalLayout, { GlobalLayoutProps } from 'global/components/lib/layout/Layout'

import NavBar from 'dfp/components/lib/layout/Navbar'
import useLayoutLogic from './useLayoutLogic'

export default function Layout(props: GlobalLayoutProps): JSX.Element {
  const [layoutLogic] = useLayoutLogic()
  const { initAccount, navbarContextMenuO365Props, onSelectAccountCallback } = layoutLogic

  return (
    <GlobalLayout
      {...props}
      navbar={<NavBar />}
      initAccount={initAccount}
      isLicenseBannerEnabled
      isMaintenanceBannerEnabled
      isNewLayout
      navbarContextMenuO365Props={navbarContextMenuO365Props}
      onSelectAccountCallback={onSelectAccountCallback}
    />
  )
}
