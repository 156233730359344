// TODO: Remove this file as part of BNFIR-3518
import { useEffect, useMemo, useCallback } from 'react'

import { process } from '@progress/kendo-data-query'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { DmarcSamplePreview } from 'global/types/api/dmarc'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import useEmailDetailsRufSampleInterface, {
  UseEmailDetailsRufSampleInterface
} from 'sen/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsRufSampleInterface'
import { downloadCSVFile } from 'global/lib/downloadFile'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getRufSamples } from 'sen/redux/features/dmarc/dmarcSlice'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import {
  update as updateRufSamplesTable,
  reset as resetRufSamplesTable
} from 'sen/redux/features/dataTables/dmarcSamples/ruf'

export default function useInvestigateDialogLogic({
  dmarcSource,
  domain
}: T.UseRufSamplesLogicProps): [
  T.RufSamplesLogicConfig,
  T.RufSamplesLogicEventHandlers,
  UseEmailDetailsRufSampleInterface
] {
  const {
    rufSamples,
    isRufSamplesLoading,
    rufSamplesError,
    rufSamplesTable,
    loadedRufSamplesOffsets,
    rufSample
  } = useAppSelector(_stores => ({
    rufSamples: _stores.dmarc.rufSamples,
    isRufSamplesLoading: isPending(_stores.dmarc.getRufSamplesApiStatus),
    rufSamplesError: getErrorMessage(_stores.dmarc.getRufSamplesApiStatus),
    rufSamplesTable: _stores.dataTables.dmarcSamples.ruf,
    loadedRufSamplesOffsets: _stores.dmarc.loadedRufSamplesOffsets,
    rufSample: _stores.dmarc.rufSample
  }))
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    if (!rufSamples) {
      dispatch(getRufSamples({ domain: domain.name, host: dmarcSource.host }))
    }

    return () => {
      dispatch(resetRufSamplesTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDownloadEmail = useCallback(() => {
    if (rufSample) {
      analyticsLib.trackEvent(
        analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_PREVIEW_DOWNLOAD_EMAIL,
        {
          domain: domain.name
        }
      )

      downloadCSVFile(rufSample.rawEmail, 'sample.eml', 'message/rfc822;charset=utf-8')
    }
  }, [rufSample, domain])

  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsRufSampleInterface({ onDownloadEmail })

  const tableData = useMemo(() => {
    const { skip, take } = rufSamplesTable

    const { data } = process(
      (rufSamples?.results || []).map((report: DmarcSamplePreview) => ({
        ...(report && {
          ...report
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.subject),
      total: rufSamples?.count || 0
    }
  }, [rufSamples, rufSamplesTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = rufSamplesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateRufSamplesTable(e.page))

        if (!loadedRufSamplesOffsets?.includes(e.page.skip)) {
          dispatch(getRufSamples({ domain: domain.name, host: dmarcSource.host }))
        }
      }
    }
  }, [rufSamplesTable, tableData, dispatch, domain, loadedRufSamplesOffsets, dmarcSource])

  const onViewEmail = useCallback(
    sample => {
      emailDetailDialogActions.onOpen({ domain: domain.name, key: sample.sampleKey })
    },
    [domain, emailDetailDialogActions]
  )

  return useMemo(() => {
    return [
      {
        tableConfig: {
          isLoaded: !!rufSamples,
          inProgress: isRufSamplesLoading,
          tableData,
          pageConfig,
          columns: rufSamplesTable.GRID_COLUMNS,
          columnsConfig: rufSamplesTable.columnsConfig,
          isFlexibleTable: tableData.total < rufSamplesTable.ITEMS_PER_PAGE
        },
        error: rufSamplesError
      },
      {
        onViewEmail
      },
      [emailDetailDialogConfig, emailDetailDialogActions]
    ]
  }, [
    rufSamples,
    rufSamplesError,
    tableData,
    pageConfig,
    isRufSamplesLoading,
    rufSamplesTable,
    onViewEmail,
    emailDetailDialogConfig,
    emailDetailDialogActions
  ])
}
