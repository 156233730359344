// TODO: Remove this file as part of BNFIR-3518
import { useCallback, useMemo } from 'react'

import { DmarcSubdomain, DmarcSource, DmarcIp } from 'global/types/api/dmarc'
import { config } from 'global/lib/config'

export interface SubdomainRecommendationsData {
  category: string
  dmarcPass: boolean
  spfPass: boolean
  dkimPass: boolean
  spfAligned: boolean
  dkimAligned: boolean
}

export interface SubdomainAnalysis {
  name: string
  pass: boolean
  isAligned: boolean
}

export interface SplitDomain {
  name: string
  display: string
  isPass: boolean
}

export interface UseInvestigateDmarcAnalysisLogic {
  getDkimSubdomainAnalysis: (subdomain: DmarcSubdomain) => SubdomainAnalysis[]
  getSpfSubdomainAnalysis: (subdomain: DmarcSubdomain) => SubdomainAnalysis[]
  isDmarcFailure: (source: DmarcSource | DmarcIp) => boolean
  isSpfFailure: (source: DmarcSource | DmarcIp) => boolean
  isDkimFailure: (source: DmarcSource | DmarcIp) => boolean
  getSpfSetupLink: (source: DmarcSource) => string
  getDkimSetupLink: (source: DmarcSource) => string
  getAlignmentLink: () => string
  getDmarcPercentage: (isFail: boolean, source: DmarcSource, subdomain: DmarcSubdomain) => string
}

export default function useInvestigateDmarcAnalysisLogic(): [UseInvestigateDmarcAnalysisLogic] {
  // private
  const getAuthDomainName = useCallback((authItem: string): string => {
    const obj = authItem.split(';')

    return obj.length === 2 ? obj[0] : authItem
  }, [])

  const isAlignedWithDomain = useCallback((domain: DmarcSubdomain['headerFrom'], spfAuthItem: string): boolean => {
    return domain.toLowerCase() === spfAuthItem.split(';')?.[0]?.toLowerCase()
  }, [])

  const getSubdomainAnalysisForItem = useCallback(
    (authItem: string, headerFrom: DmarcSubdomain['headerFrom']): SubdomainAnalysis | undefined => {
      if (authItem) {
        return {
          name: getAuthDomainName(authItem),
          pass: authItem.toLowerCase().includes(';pass'),
          isAligned: isAlignedWithDomain(headerFrom, authItem)
        }
      }
      return undefined
    },
    [getAuthDomainName, isAlignedWithDomain]
  )

  const getSubdomainAnalysisForAuthList = useCallback(
    (authResponse: DmarcSubdomain['dkimAuthResp'], headerFrom: DmarcSubdomain['headerFrom']): SubdomainAnalysis[] => {
      return authResponse.reduce((all: SubdomainAnalysis[], authItem: string) => {
        const analyzedItem = getSubdomainAnalysisForItem(authItem, headerFrom)

        if (analyzedItem) {
          return [...all, analyzedItem]
        }

        return all
      }, [])
    },
    [getSubdomainAnalysisForItem]
  )

  // public
  const getDkimSubdomainAnalysis: UseInvestigateDmarcAnalysisLogic['getDkimSubdomainAnalysis'] = useCallback(
    subdomain => {
      return getSubdomainAnalysisForAuthList(subdomain.dkimAuthResp, subdomain.headerFrom)
    },
    [getSubdomainAnalysisForAuthList]
  )

  const getSpfSubdomainAnalysis: UseInvestigateDmarcAnalysisLogic['getSpfSubdomainAnalysis'] = useCallback(
    subdomain => {
      return getSubdomainAnalysisForAuthList(subdomain.spfAuthResp, subdomain.headerFrom)
    },
    [getSubdomainAnalysisForAuthList]
  )

  const isDmarcFailure: UseInvestigateDmarcAnalysisLogic['isDmarcFailure'] = useCallback(source => {
    return source.dmarcFail >= 1 && source.dmarcFailRatio > 1
  }, [])

  const isSpfFailure: UseInvestigateDmarcAnalysisLogic['isSpfFailure'] = useCallback(source => {
    return source.spfFail >= 1 && source.spfFailRatio > 1
  }, [])

  const isDkimFailure: UseInvestigateDmarcAnalysisLogic['isDkimFailure'] = useCallback(source => {
    return source.dkimFail >= 1 && source.dkimFailRatio > 1
  }, [])

  const getSpfSetupLink: UseInvestigateDmarcAnalysisLogic['getSpfSetupLink'] = useCallback(source => {
    if (source.product && source.product.spfFixUrl) {
      return source.product.spfFixUrl
    }

    return config.LINKS.SPF_SETUP_DOC
  }, [])

  const getDkimSetupLink: UseInvestigateDmarcAnalysisLogic['getDkimSetupLink'] = useCallback(source => {
    if (source.product && source.product.dkimFixUrl) {
      return source.product.dkimFixUrl
    }

    return config.LINKS.DKIM_SETUP_DOC
  }, [])

  const getAlignmentLink: UseInvestigateDmarcAnalysisLogic['getAlignmentLink'] = useCallback(() => {
    return config.LINKS.DMARC_ALIGNMENT_SETUP_DOC
  }, [])

  const getDmarcPercentage: UseInvestigateDmarcAnalysisLogic['getDmarcPercentage'] = useCallback(
    (isFail, source, subdomain) => {
      const totalValueInCategory = isFail ? source.dmarcFail : source.dmarcPass

      if (totalValueInCategory > 0) {
        const percentage = (subdomain.emailCount / totalValueInCategory) * 100

        return percentage < 1 ? '<1' : String(Math.round(percentage))
      }

      return String(100)
    },
    []
  )

  return useMemo(
    () => [
      {
        getDkimSubdomainAnalysis,
        getSpfSubdomainAnalysis,
        isDmarcFailure,
        isSpfFailure,
        isDkimFailure,
        getSpfSetupLink,
        getDkimSetupLink,
        getAlignmentLink,
        getDmarcPercentage
      }
    ],
    [
      getDkimSubdomainAnalysis,
      getSpfSubdomainAnalysis,
      isDmarcFailure,
      isSpfFailure,
      isDkimFailure,
      getSpfSetupLink,
      getDkimSetupLink,
      getAlignmentLink,
      getDmarcPercentage
    ]
  )
}
