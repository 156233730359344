// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'
import tabStyles from 'global/theme/tabStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)
  const tabClasses = tabStyles(theme)

  return {
    ...defaultDialogStyles,
    alert: {
      marginBottom: theme.spacing(2)
    },
    buttonContainer: {
      marginTop: theme.spacing(1)
    },

    ...tabClasses
  }
})
