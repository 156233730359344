import React, { useMemo } from 'react'

import {
  Dialog,
  Zoom,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Alert
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import useDeleteDomainDialogLogic, {
  DeleteDomainDialogLogicProps
} from 'dfp/components/lib/dialogs/deleteDomainDialog/useDeleteDomainDialogLogic'
import styles from 'dfp/components/lib/dialogs/deleteDomainDialog/deleteDomainDialogStyles'

const BASE_I18N_KEY = 'dfp.app.delete_domain_dialog'

export const DeleteDoaminDialog: React.FC<DeleteDomainDialogLogicProps> = ({ onClose, domain }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [config, formEvents] = useDeleteDomainDialogLogic({ onClose, domain })

  const subtitlePrefix = config.isDeleteDomainSuccess ? 'deleted' : 'init'

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} maxWidth="sm" fullWidth open TransitionComponent={Zoom}>
        {config.isDeleteDomainInProgress && <LinearProgress />}

        <DialogTitle>
          {config.deleteDomainError && (
            <Alert className={classes.alert} severity="error">
              {errorFormatMessage(config.deleteDomainError)}
            </Alert>
          )}

          <Typography className={classes.dialogTitle}>{formatMessage('title', { domainName: domain.name })}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Typography className={classes.dialogText}>
              {formatMessage(`${subtitlePrefix}_subtitle_1`, { domainName: domain.name })}
            </Typography>
            <Typography className={classes.dialogText}>
              {formatMessage(`${subtitlePrefix}_subtitle_2`, { domainName: domain.name })}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          {config.isDeleteDomainSuccess && (
            <Button color="primary" variant="text" size="large" onClick={onClose}>
              {formatMessage('dismiss')}
            </Button>
          )}
          {!config.isDeleteDomainSuccess && (
            <Button
              color="primary"
              variant="text"
              size="large"
              disabled={config.isDeleteDomainInProgress}
              onClick={formEvents.onClose}
            >
              {formatMessage('cancel')}
            </Button>
          )}
          {!config.isDeleteDomainSuccess && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.dangerButton}
              disabled={config.isDeleteDomainInProgress}
              onClick={formEvents.onDelete}
            >
              {formatMessage('delete_this_domain')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    ),
    [config, formEvents, domain, classes, errorFormatMessage, formatMessage, onClose, subtitlePrefix]
  )
}

export default DeleteDoaminDialog
