import React from 'react'
import { Routes, Route } from 'react-router-dom'

import DomainFraud from 'dfp/components/pages/domainFraud/DomainFraud'
import DomainFraudDmarcReviewWithUnknownTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithUnknownTab'
import DomainFraudDmarcReviewWithApprovedTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithApprovedTab'
import DomainFraudDmarcReviewWithRejectedTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithRejectedTab'
import EmptyReport from 'dfp/components/pages/emptyReport/EmptyReport'
import MissingRequirement from 'dfp/components/pages/missingRequirement/MissingRequirement'
import SigninConnect from 'dfp/components/pages/signinConnect/SigninConnect'
import Signup from 'dfp/components/pages/signup/signinSignup/Signup'
import SigninSignupComplete from 'dfp/components/pages/signupComplete/SigninSignupComplete'
import StartTrial from 'dfp/components/pages/startTrial/StartTrial'

import PublicRoute from 'dfp/components/lib/routes/PublicRoute'
import PrivateRoute from 'dfp/components/lib/routes/PrivateRoute'
import routesConfig, { UiRoute } from 'dfp/lib/routes/routesConfig'

import UnregisteredPage from 'dfp/components/pages/unregisteredPage/UnregisteredPage'

type DisableUserCheck = boolean
export type RouteParams = [UiRoute, React.FC<any>, DisableUserCheck?]

const publicRoutes: RouteParams[] = [
  // Signup routes
  [routesConfig.SIGNIN_SIGNUP, Signup],
  [routesConfig.SIGNIN_CONNECT, SigninConnect, true],
  [routesConfig.SIGNUP_COMPLETE, SigninSignupComplete, true]
]

const privateRoutes: RouteParams[] = [
  // Domain Fraud routes
  [routesConfig.DOMAIN_FRAUD, DomainFraud],
  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB, DomainFraudDmarcReviewWithUnknownTab],
  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB, DomainFraudDmarcReviewWithApprovedTab],
  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_REJECTED_TAB, DomainFraudDmarcReviewWithRejectedTab],
  [routesConfig.EMPTY_REPORT, EmptyReport],
  [routesConfig.MISSING_REQUIREMENTS, MissingRequirement],
  [routesConfig.START_TRIAL, StartTrial]
]

const AppRoutes = (
  <Routes>
    {/* Public routes */}
    {publicRoutes
      .filter(routeparams => routeparams[0])
      .map(routeParams => {
        const [route, Component] = routeParams

        return <Route key={route.id} path={route.path} element={<PublicRoute route={route} Component={Component} />} />
      })}

    {/* Private routes */}
    {privateRoutes
      .filter(routeparams => routeparams[0])
      .map(routeParams => {
        const [routeConfig, Component] = routeParams

        return (
          <Route
            key={routeConfig.id}
            path={routeConfig.path}
            element={<PrivateRoute route={routeConfig} Component={Component} />}
          />
        )
      })}

    {/* If route is not registered */}
    <Route
      path="*"
      element={<PublicRoute route={routesConfig.SIGNIN_SIGNUP} Component={UnregisteredPage} disableUserCheck />}
    />
  </Routes>
)

export default AppRoutes
