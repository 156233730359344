import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { setActivePath } from 'global/redux/features/app/appSlice'
import Loading from 'global/components/lib/loading/Loading'
import { isPending } from 'global/redux/toolkit/api'
import { UiRoute } from 'global/lib/routes/routesConfig'

export interface Props {
  route?: UiRoute
  Component: any
}

export default function privateRoute(
  customRenderComponent?: (component: any, childProps: any, route: any) => JSX.Element
): React.FC<Props> {
  const PrivateRoute: React.FC<Props> = ({ Component, route }: Props) => {
    const dispatch = useAppDispatch()
    const params = useParams()
    const location = useLocation()
    const { isLogoutPending, isLoggedOut } = useAppSelector(_stores => ({
      isLogoutPending: isPending(_stores.auth.logoutApiStatus),
      isLoggedOut: _stores.app.justLoggedOut
    }))

    const [shouldRenderComponent, setShouldRenderComponent] = useState(false)

    // reset when path is changed
    useEffect(() => {
      setShouldRenderComponent(true)
      return () => {
        setShouldRenderComponent(false)
      }
    }, [location.pathname])

    useEffect(() => {
      if (route) {
        dispatch(
          setActivePath({
            id: route.id || '',
            url: location.pathname,
            params,
            metadata: route.metadata,
            isNavbarVisible: true,
            isPublicRoute: false
          } as any)
        )
      }
      // Added the exception below because adding params causes issues with the admin dashboard
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location.pathname, route])

    const RenderComponent = useCallback(
      (childProps: any) => {
        if (customRenderComponent) {
          return customRenderComponent(Component, childProps, route)
        }

        return <Component {...childProps} />
      },
      [Component, route]
    )

    return useMemo(() => {
      if (!shouldRenderComponent || isLoggedOut || isLogoutPending) {
        return <Loading />
      }
      return <RenderComponent route={route} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedOut, isLogoutPending, location.pathname, route, shouldRenderComponent])
  }

  return PrivateRoute
}
