import React, { useMemo } from 'react'

import { Typography, Button, DialogContent, DialogActions, Grid } from '@barracuda-internal/bds-core'
import ReactCopyToClipboard from 'react-copy-to-clipboard'

import { useFormatMessage } from 'global/lib/localization'
import useCopyToClipboardLogic from 'global/components/lib/copyToClipboard/useCopyToClipboardLogic'

import styles from 'dfp/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import { StepProps } from 'dfp/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'

const BASE_I18N_KEY = 'dfp.app.domain_wizard_dialog.spf_check_success'

export const DomainWizardStepSpfCheckSuccess: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [onCopyToClipBoard, isCopiedVisible] = useCopyToClipboardLogic()

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent} data-step-name="spf-check-success">
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>{formatMessage('title')}</Typography>
            <Grid item>
              <Grid container direction="column">
                <Typography className={classes.subtitle}>{formatMessage('spf_record')}</Typography>
                <ReactCopyToClipboard text={config.spfRecord?.entry || ''} onCopy={onCopyToClipBoard}>
                  <Typography className={classes.copyText}>{config.spfRecord?.entry}</Typography>
                </ReactCopyToClipboard>
                <Typography className={classes.copiedText}>
                  {isCopiedVisible && formatMessage('copied')}&nbsp;
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.dialogText}>{formatMessage('subtitle_1')}</Typography>
            <Typography className={classes.dialogText}>{formatMessage('subtitle_2')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('configure_dmarc')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events, config, onCopyToClipBoard, isCopiedVisible]
  )
}

export default DomainWizardStepSpfCheckSuccess
