import React, { useMemo } from 'react'

import useDFPContentCondition, {
  UseDFPContentConditionParams,
  UIState
} from 'sen/components/lib/useDFPContentCondition/useDFPContentCondition'
import NoPermission from 'sen/components/lib/noPermission/NoPermission'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'

export type UseDFPContentConditionProps = UseDFPContentConditionParams

export interface DFPContentLoader {
  default: (props: UseDFPContentConditionProps, userEmail: string) => any
  loading?: (props: UseDFPContentConditionProps, userEmail: string) => any
  missedEntitlement?: (props: UseDFPContentConditionProps, userEmail: string) => any
  scanInProgress?: (props: UseDFPContentConditionProps, userEmail: string) => any
  failedToLoadScan?: (props: UseDFPContentConditionProps, userEmail: string) => any
}

export default function dfpContentLoader(loaderConfig: DFPContentLoader) {
  const DFPContentLoader: React.FC<UseDFPContentConditionProps> = props => {
    const [uiState, userEmail] = useDFPContentCondition()

    return useMemo(() => {
      switch (uiState) {
        case UIState.missedEntitlement:
          return loaderConfig.missedEntitlement?.(props, userEmail) || <NoPermission />
        default:
          return <ContentLayout data-field="content-layout">{loaderConfig.default(props, userEmail)}</ContentLayout>
      }
    }, [uiState, userEmail, props])
  }

  return DFPContentLoader
}
