/* eslint-disable no-nested-ternary */
// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo, useCallback } from 'react'

import {
  Typography,
  DataTable,
  DataTableColumn,
  Button,
  Grid,
  Paper,
  Tooltip,
  Alert
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'
import {
  Delete as DeleteIcon,
  HelpOutline as HelpOutlineIcon,
  Close as CloseIcon
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import getDmarcType from 'global/components/lib/dmarcTypeLabel/getDmarcType'
import DmarcTypeLabel from 'global/components/lib/dmarcTypeLabel/DmarcTypeLabel'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'

import styles from 'sen/components/pages/domainFraud/content/domainFraudContentStyles'
import useCompanyDomainsContentLogic, {
  ModifiedCompanyDomain
} from 'sen/components/pages/domainFraud/content/useDomainFraudContentLogic'
import DmarcEnforcementWizardDialog from 'sen/components/lib/dialogs/dmarcEnforcementWizard/DmarcEnforcementWizardDialog'
import { EnforcementGoal } from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'
import AddDomainWizardDialog from 'sen/components/lib/dialogs/addDomainWizardDialog/AddDomainWizardDialog'
import DeleteDomainDialog from 'sen/components/lib/dialogs/deleteDomainDialog/DeleteDomainDialog'
import DomainWizardDialog from 'sen/components/lib/dialogs/domainWizardDialog/DomainWizardDialog'

const BASE_I18N_KEY = 'sen.app.domain_fraud'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.domains'

const DomainFraudContent: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [readyToEnforceConfig, tableConfig, eventHandlers, dialogConfigs] = useCompanyDomainsContentLogic()

  const renderHeaderWithTooltipFor = useCallback(
    (type: string) => () => {
      return (
        <>
          {tableFormatMessage(type)}
          &nbsp;
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={tableFormatMessage(`${type}_tooltip`)}
            placement="top-start"
            data-field={`table-column-${type}-tooltip`}
          >
            <div className={classes.HelpOutlineIconWrapper}>
              <HelpOutlineIcon className={classes.HelpOutlineIcon} />
            </div>
          </Tooltip>
        </>
      )
    },
    [classes, tableFormatMessage]
  )

  return useMemo(() => {
    return (
      <Grid className={classes.container} container direction="column" data-field="root-grid">
        <Grid item>
          <Typography className={classes.title} data-field="domain-fraud-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="domain-fraud-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>

        <Grid container data-field="ready-to-enforce-grid-paper">
          <Grid item xs={9}>
            {readyToEnforceConfig.isVisible && !readyToEnforceConfig.isHidden && (
              <Paper className={classes.readyToEnforcePaper} elevation={0} data-field="ready-to-enforce-paper">
                <CloseIcon className={classes.closeReadyToEnforce} onClick={readyToEnforceConfig.onHide} />
                <Typography className={classes.readyToEnforceTitle} data-field="ready-to-enforce-title">
                  {formatMessage('ready_to_enforce.title')}
                </Typography>
                <Typography
                  className={classes.readyToEnforceSubtitle}
                  variant="subtitle2"
                  data-field="ready-to-enforce-subtitle_1"
                >
                  {formatMessage('ready_to_enforce.subtitle_1')}
                </Typography>
                {readyToEnforceConfig.readyForEnforcingDmarcItems.map(companyDomain => (
                  <Typography
                    key={companyDomain.name}
                    className={classes.readyToEnforceSubtitle}
                    variant="subtitle2"
                    data-field="ready-to-enforce-subtitle_2"
                  >
                    &nbsp;&nbsp;&nbsp;{`- ${companyDomain.name}`}
                  </Typography>
                ))}
                <Typography
                  className={classes.readyToEnforceSubtitle}
                  variant="subtitle2"
                  data-field="ready-to-enforce-subtitle_2"
                >
                  {formatMessage('ready_to_enforce.subtitle_2')}
                </Typography>
              </Paper>
            )}
          </Grid>
        </Grid>

        <Grid item data-field="domains-table-grid-paper">
          {tableConfig.error && (
            <Alert className={classes.error} severity="error" data-field="domain-fraud-alert">
              {errorFormatMessage(tableConfig.error)}
            </Alert>
          )}

          <Paper className={classes.tableCard} elevation={0} data-field="domains-table-paper">
            <Grid container justifyContent="flex-end">
              <Button
                className={classes.addDomainButton}
                color="primary"
                variant="contained"
                size="large"
                onClick={eventHandlers.onAddDomainForDmarcReporting}
              >
                {formatMessage('add_domain_button')}
              </Button>
            </Grid>

            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.tableCircularProgress}>
                <CircularProgress data-field="loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid container direction="column" className={classes.tableWrapper} data-field="domains-table-wrapper">
                <Grid
                  className={`${tableConfig.isFlexibleTable ? classes.flexibleTableArea : classes.tableArea} ${
                    !tableConfig.tableData.total ? 'empty-table' : ''
                  }`}
                  data-table="domains"
                  item
                  xs={12}
                  data-field="grid-table"
                >
                  <DataTable
                    data-testid="domains-table"
                    className={`${
                      tableConfig.tableData.total
                        ? tableConfig.isFlexibleTable
                          ? classes.flexibleDataTable
                          : classes.dataTable
                        : classes.emptyDataTable
                    } alerts-table`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    pager={Pager}
                  >
                    <DataTableNoRecords data-field="empty-table">
                      <Typography>{!tableConfig.tableData.total && tableFormatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>

                    <DataTableColumn
                      field={tableConfig.columns.DOMAIN}
                      {...tableConfig.columnsConfig[tableConfig.columns.DOMAIN]}
                      title={tableFormatMessage(tableConfig.columns.DOMAIN)}
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-domain">
                          <CellText className={classes.normalCell} data-field="table-column-domain-text">
                            {dataItem.name}
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={tableConfig.columns.STATUS}
                      {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                      title={tableFormatMessage(tableConfig.columns.STATUS)}
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-alerts">
                          <CellLabelType>
                            <DmarcTypeLabel
                              status={getDmarcType(dataItem.state)}
                              data-field="table-column-status-label"
                            />
                            {dataItem.isReadyToBeEnforced && (
                              <>
                                &nbsp; &nbsp;
                                <Button
                                  className={classes.enforceDmarc}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    eventHandlers.onOpenEnforcementWizard(dataItem)
                                  }}
                                >
                                  {tableFormatMessage('enforce_dmarc')}
                                </Button>
                              </>
                            )}
                          </CellLabelType>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={tableConfig.columns.UNKNOWN_SOURCES}
                      {...tableConfig.columnsConfig[tableConfig.columns.UNKNOWN_SOURCES]}
                      headerCell={renderHeaderWithTooltipFor('unknown_sources')}
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-unknown-sources">
                          <CellText
                            ellipsis
                            className={classes.normalCell}
                            data-field="table-column-unknown-sources-text"
                          >
                            {dataItem.unknownSources}
                          </CellText>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={tableConfig.columns.FAILURES}
                      {...tableConfig.columnsConfig[tableConfig.columns.FAILURES]}
                      headerCell={renderHeaderWithTooltipFor('failures')}
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-failures">
                          <CellLabelType>
                            <CellText ellipsis className={classes.normalCell} data-field="table-column-failures-text">
                              {dataItem.failures}
                            </CellText>
                          </CellLabelType>
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      field={tableConfig.columns.ACTION}
                      {...tableConfig.columnsConfig[tableConfig.columns.ACTION]}
                      title=" "
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-action">
                          {!tableConfig.isDomainsStatsLoaded && (
                            <CircularProgress data-field="table-column-status-loader" />
                          )}
                          {tableConfig.isDomainsStatsLoaded && (
                            <Grid container direction="row">
                              {dataItem.reviewButtonMode && (
                                <Button
                                  color="primary"
                                  variant="text"
                                  disabled={dataItem.reviewButtonIsDisabled}
                                  onClick={() => {
                                    eventHandlers.onActionSelected(dataItem)
                                  }}
                                >
                                  {tableFormatMessage(`actions.${dataItem.reviewButtonMode}`)}
                                </Button>
                              )}
                            </Grid>
                          )}
                        </Cell>
                      )}
                    />

                    <DataTableColumn
                      width={100}
                      field={tableConfig.columns.DELETE}
                      {...tableConfig.columnsConfig[tableConfig.columns.DELETE]}
                      title=" "
                      cell={({ dataItem }: { dataItem: ModifiedCompanyDomain }) => (
                        <Cell className={classes.cell} data-field="table-column-delete">
                          <Grid container direction="row" justifyContent="center">
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              disableHoverListener={!dataItem.deleteIsDisabled}
                              title={tableFormatMessage('delete_tooltip')}
                              placement="top-start"
                              data-field="table-column-delete-tooltip"
                            >
                              <div
                                role="presentation"
                                onClick={() => {
                                  if (!dataItem.deleteIsDisabled) {
                                    eventHandlers.onDeleteDomain(dataItem)
                                  }
                                }}
                              >
                                <DeleteIcon
                                  className={classes[dataItem.deleteIsDisabled ? 'disabledDeleteIcon' : 'deleteIcon']}
                                  data-testid={`delete-${dataItem.name}`}
                                  aria-disabled={dataItem.deleteIsDisabled}
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>

        {dialogConfigs.dmarcEnforcementWizard.isOpen && dialogConfigs.dmarcEnforcementWizard.domain && (
          <DmarcEnforcementWizardDialog
            onClose={dialogConfigs.dmarcEnforcementWizard.onClose}
            domain={dialogConfigs.dmarcEnforcementWizard.domain}
            goal={EnforcementGoal.enforcement}
          />
        )}

        {dialogConfigs.addDomainWizard.isOpen && (
          <AddDomainWizardDialog
            onClose={dialogConfigs.addDomainWizard.onClose}
            domain={dialogConfigs.addDomainWizard.domain}
          />
        )}

        {dialogConfigs.deleteDomain.isOpen && dialogConfigs.deleteDomain.domain && (
          <DeleteDomainDialog onClose={dialogConfigs.deleteDomain.onClose} domain={dialogConfigs.deleteDomain.domain} />
        )}

        {dialogConfigs.domainWizard.isOpen && dialogConfigs.domainWizard.domain && (
          <DomainWizardDialog onClose={dialogConfigs.domainWizard.onClose} domain={dialogConfigs.domainWizard.domain} />
        )}
      </Grid>
    )
  }, [
    classes,
    formatMessage,
    tableConfig,
    errorFormatMessage,
    tableFormatMessage,
    eventHandlers,
    renderHeaderWithTooltipFor,
    readyToEnforceConfig,
    dialogConfigs
  ])
}

export default DomainFraudContent
