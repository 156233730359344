// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Dialog, Zoom } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'

import useDmarcEnforcementWizardDialogLogic from 'sen/components/lib/dialogs/dmarcEnforcementWizard/useDmarcEnforcementWizardDialogLogic'
import {
  DmarcEnforcementWizardDialogProps,
  EnforcementSteps
} from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'
import DmarcEnforcementsStepInit from 'sen/components/lib/dialogs/dmarcEnforcementWizard/steps/DmarcEnforcementStepInit'
import DmarcEnforcementsStepDmarcCheck from 'sen/components/lib/dialogs/dmarcEnforcementWizard/steps/DmarcEnforcementStepDmarcCheck'
import DmarcEnforcementsStepResult from 'sen/components/lib/dialogs/dmarcEnforcementWizard/steps/DmarcEnforcementStepResult'

export const EnforceDmarcDialog: React.FC<DmarcEnforcementWizardDialogProps> = ({ onClose, domain, goal }) => {
  const [logicConfig, formEvents] = useDmarcEnforcementWizardDialogLogic({ onClose, domain, goal })

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
        maxWidth="sm"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-dialog-type="dmarc-enforcement-wizard"
      >
        {logicConfig.step === EnforcementSteps.init && (
          <DmarcEnforcementsStepInit config={logicConfig} events={formEvents} />
        )}
        {logicConfig.step === EnforcementSteps.dmarcCheck && (
          <DmarcEnforcementsStepDmarcCheck config={logicConfig} events={formEvents} />
        )}
        {logicConfig.step === EnforcementSteps.result && (
          <DmarcEnforcementsStepResult config={logicConfig} events={formEvents} />
        )}
      </Dialog>
    ),
    [logicConfig, formEvents]
  )
}

EnforceDmarcDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  domain: PropTypes.any.isRequired,
  goal: PropTypes.any.isRequired
}

export default EnforceDmarcDialog
