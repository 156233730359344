// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Alert
} from '@barracuda-internal/bds-core'

import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import styles from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogTypes'
import DomainValues from 'sen/components/lib/dialogs/addDomainWizardDialog/steps/DomainValues'

const BASE_I18N_KEY = 'sen.app.add_domain_dialog'

export type DomainInfoConfig = [string, string, 2 | 4]

export const AddDomainStepVerificationFailure: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <>
        {config.isVerifyDomainInProgress && <LinearProgress />}

        <DialogTitle data-step-name="verification-failure">
          {config.verifyDomainError && (
            <Alert className={classes.alert} severity="error" data-field="verification-failure-alert">
              {errorFormatMessage(config.verifyDomainError)}
            </Alert>
          )}

          <Typography className={classes.dialogTitle}>{config.domainName}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>
              {formatMessage('verification_failure.subtitle_1')}
            </Typography>
            {!config.isVerifyDomainInProgress && <DomainValues domainValues={config.verifiedDomain} />}
            <Typography className={classes.dialogText}>
              {formatMessage('verification_failure.subtitle_2', {
                b: (txt: string) => <b key={txt}>{txt}</b>
              })}
              :
            </Typography>
            <Typography className={classes.dialogText}>- {formatMessage('verification_failure.subtitle_3')}</Typography>
            <Typography className={classes.dialogText}>- {formatMessage('verification_failure.subtitle_4')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={config.isVerifyDomainInProgress}
            onClick={events.onNextStep}
          >
            {formatMessage('next')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, config, events, errorFormatMessage]
  )
}

export default AddDomainStepVerificationFailure
