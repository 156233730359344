// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import tableStyles from 'global/components/lib/grid/tableStyles'
import { COLORS } from 'global/configs/theme/defaults'

const EMPTY_TABLE_HEIGHT = 100
const CELL_HEIGHT = 75
const TABLE_MAX_HEIGHT = 450

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT, EMPTY_TABLE_HEIGHT)

  return {
    // table styles
    ...defaultTableStyles,
    tableWrapper: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      paddingTop: 0
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      maxHeight: TABLE_MAX_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      marginBottom: theme.spacing(2)
    },
    reservedTableArea: {
      height: EMPTY_TABLE_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      maxHeight: TABLE_MAX_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    emptyDataTable: {
      ...defaultTableStyles.emptyDataTable,
      padding: `0 ${theme.spacing(1)}px`
    },
    flexibleDataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },

    textWithMargin: {
      marginBottom: theme.spacing(1)
    },
    domainWithMargin: {
      marginBottom: theme.spacing(4)
    },
    passedIcon: {
      width: 20,
      verticalAlign: -7,
      color: COLORS.GREEN_400,
      marginRight: theme.spacing(0.5)
    },
    failedIcon: {
      width: 20,
      verticalAlign: -7,
      color: COLORS.RED_600,
      marginRight: theme.spacing(0.5)
    }
  }
})
