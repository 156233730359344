// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  return {
    textWithMargin: {
      marginTop: theme.spacing(1)
    },
    linkText: {
      cursor: 'pointer',
      color: COLORS.BLUE
    }
  }
})
