// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import { Typography, DialogContent, Grid, DialogActions, Button } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.domain_wizard_dialog.dmarc_check'

export const DomainWizardStepDmarcCheck: React.FC<StepProps> = ({ events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>{formatMessage('title')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events]
  )
}

export default DomainWizardStepDmarcCheck
