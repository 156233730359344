// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import { Typography, Button, DialogContent, DialogActions, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/domainWizardDialog/domainWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.domain_wizard_dialog.init'

export const DomainWizardStepInit: React.FC<StepProps> = ({ domain, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent} data-step-name="init">
          <Grid container direction="column">
            <Typography className={classes.dialogBiggerText}>{formatMessage('title')}</Typography>
            <Typography className={classes.dialogText}>
              {formatMessage('subtitle_1', { domainName: domain.name })}
            </Typography>
            <Typography className={classes.dialogText}>{formatMessage('subtitle_2')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" size="large" onClick={events.onCloseDialog}>
            {formatMessage('cancel')}
          </Button>
          <Button color="primary" variant="contained" size="large" onClick={events.onNextStep}>
            {formatMessage('check_my_spf')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events, domain]
  )
}

export default DomainWizardStepInit
