// TODO: Remove this file as part of BNFIR-3518
import { DmarcSource, CompanyDomain, DmarcSamplePreview, DmarcSubdomain } from 'global/types/api/dmarc'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'

export enum Views {
  viewBySendingdomain,
  viewBySendingips,
  rufSamples,
  rufSamplesPreview
}

export enum Tabs {
  fail = 'fail',
  pass = 'pass'
}

export interface TableConfig<DataType> {
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: DataType[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
}

// UI component
export interface InvestigateDmarcDialogProps {
  onClose: () => void
  domain: CompanyDomain
  dmarcSource: DmarcSource
}

// Logic component
export interface LogicConfig {
  isLoaded: boolean
  error: string | undefined
  selectedView: Views
  selectedTab: Tabs
  tabPercentages: string[]
}

export interface EventHandlers {
  onChangeTab: (e: any, newTab: Tabs) => void
  onChangeView: (newView: Views) => void
  onChangeViewBack: () => void
  onCloseDialog: () => void
}

// views
export interface ViewProps {
  domain: CompanyDomain
  dmarcSource: DmarcSource
  selectedTab: Tabs
  onClose: () => void
}

export interface UseViewLogicProps {
  domain: CompanyDomain
  dmarcSource: DmarcSource
  selectedTab: Tabs
}

export interface ViewsLogicConfig {
  isFail: boolean
  tableConfig: TableConfig<DmarcSubdomain>
}

// ruf samples
export interface RufSamplesProps {
  domain: CompanyDomain
  dmarcSource: DmarcSource
  onBack: () => void
  onClose: () => void
}

export interface UseRufSamplesLogicProps {
  domain: CompanyDomain
  dmarcSource: DmarcSource
}

export interface RufSamplesLogicConfig {
  error: string | undefined
  tableConfig: TableConfig<DmarcSamplePreview>
}

export interface RufSamplesLogicEventHandlers {
  onViewEmail: (sample: DmarcSamplePreview) => void
}
