// TODO: Remove this file as part of BNFIR-3518
import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    alert: {
      marginBottom: theme.spacing(2)
    },
    domainValuesWrapper: {
      marginTop: theme.spacing(1)
    },
    domainValueWrapper: {
      width: '100%'
    },
    domainValueTitleText: {
      ...defaultDialogStyles.text,
      marginTop: theme.spacing(2)
    },
    copyText: {
      ...defaultDialogStyles.dialogText,
      cursor: 'pointer'
    },
    copiedText: {
      ...defaultDialogStyles.dialogSmallText,
      marginTop: 0
    },
    stepper: {
      position: 'absolute',
      right: 0
    }
  }
})
