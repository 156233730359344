// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import { Success as SuccessIcon, Critical as CriticalIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { v4 as uuidv4 } from 'uuid'

import { useFormatMessage } from 'global/lib/localization'

import { SubdomainAnalysis } from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useInvestigateDmarcAnalysis'
import styles from 'sen/components/lib/dialogs/investigateDmarc/views/lib/dmarcAuthAnalysisStyles'

const BASE_I18N_KEY = 'sen.app.data_tables.view_by_domain_table'

export enum AnalysisCellTypes {
  spf = 'SPF',
  dkim = 'DKIM'
}

export interface DmarcAuthAnalysisCellProps {
  type: AnalysisCellTypes
  analyzedData: SubdomainAnalysis[]
}

export const DmarcAuthAnalysisCell: React.FC<DmarcAuthAnalysisCellProps> = ({ analyzedData, type }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="column">
        {analyzedData.map((data, index) => (
          <data key={uuidv4()}>
            {data.pass && (
              <Grid className={index ? classes.textWithMargins : classes.textWithMargin}>
                {formatMessage('passed')}&nbsp;{type}&nbsp;
                <SuccessIcon className={classes.passedIcon} />
              </Grid>
            )}
            {!data.pass && (
              <Grid className={index ? classes.failedTextWithMargins : classes.failedTextWithMargin}>
                {formatMessage('failed')}&nbsp;{type}&nbsp;
                <CriticalIcon className={classes.failedIcon} />
              </Grid>
            )}
            {data.isAligned && (
              <Grid>
                {formatMessage('aligned')}&nbsp;
                <SuccessIcon className={classes.passedIcon} />
              </Grid>
            )}
            {!data.isAligned && (
              <Grid className={classes.failedText}>
                {formatMessage('misaligned')}&nbsp;
                <CriticalIcon className={classes.failedIcon} />
              </Grid>
            )}
          </data>
        ))}
      </Grid>
    ),
    [analyzedData, classes, formatMessage, type]
  )
}

export default DmarcAuthAnalysisCell
