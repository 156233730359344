// TODO: Remove this file as part of BNFIR-3518
/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  DataTable,
  Typography,
  DataTableColumn
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'
import { v4 as uuidv4 } from 'uuid'

import { DmarcSubdomain } from 'global/types/api/dmarc'
import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import styles from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogStyles'
import viewByDomainStyles from 'sen/components/lib/dialogs/investigateDmarc/views/viewByDomainStyles'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useViewByDomainLogic from 'sen/components/lib/dialogs/investigateDmarc/views/useViewByDomainLogic'
import DmarcAuthAnalysisCell, {
  AnalysisCellTypes
} from 'sen/components/lib/dialogs/investigateDmarc/views/lib/DmarcAuthAnalysisCell'
import DmarcRecommendationCell from 'sen/components/lib/dialogs/investigateDmarc/views/lib/DmarcRecommendationCell'
import useInvestigateDmarcAnalysisLogic from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useInvestigateDmarcAnalysis'

const BASE_I18N_KEY = 'sen.app.investigate_dmarc_dialog'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.view_by_domain_table'

export const ViewBySendingdomain: React.FC<T.ViewProps> = ({ onClose, selectedTab, domain, dmarcSource }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const viewByDomainClasses = viewByDomainStyles()
  const [investigateDmarcAnalysisLogic] = useInvestigateDmarcAnalysisLogic()
  const [logicConfig] = useViewByDomainLogic({ selectedTab, domain, dmarcSource })

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent}>
          {logicConfig.tableConfig.isLoaded && (
            <Grid
              container
              direction="column"
              className={viewByDomainClasses.tableWrapper}
              data-field="domains-table-wrapper"
            >
              <Grid
                className={`${
                  logicConfig.tableConfig.isFlexibleTable
                    ? viewByDomainClasses.flexibleTableArea
                    : viewByDomainClasses.tableArea
                } ${!logicConfig.tableConfig.tableData.total ? 'empty-table' : ''}`}
                data-table="view-by-domain"
                item
                xs={12}
                data-field="grid-table"
              >
                {logicConfig.tableConfig.inProgress && <LinearProgress />}

                <DataTable
                  data-testid="view-by-domain-table"
                  className={`${
                    logicConfig.tableConfig.tableData.total
                      ? logicConfig.tableConfig.isFlexibleTable
                        ? viewByDomainClasses.flexibleDataTable
                        : viewByDomainClasses.dataTable
                      : viewByDomainClasses.emptyDataTable
                  } alerts-table`}
                  data={logicConfig.tableConfig.tableData}
                  {...logicConfig.tableConfig.pageConfig}
                  pager={Pager}
                >
                  <DataTableNoRecords data-field="empty-table">
                    <Typography>
                      {!logicConfig.tableConfig.tableData.total && tableFormatMessage('empty_table')}
                    </Typography>
                  </DataTableNoRecords>

                  <DataTableColumn
                    field={logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']}
                    {...logicConfig.tableConfig.columnsConfig[
                      logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']
                    ]}
                    title={tableFormatMessage(
                      `${logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']}`
                    )}
                    cell={({ dataItem }: { dataItem: DmarcSubdomain }) => (
                      <Cell className={viewByDomainClasses.cell} data-field="table-column-passed-reports">
                        <CellText
                          ellipsis
                          className={viewByDomainClasses.normalCell}
                          data-field="table-column-passed-reports-text"
                        >
                          <Grid className={viewByDomainClasses.textWithMargin}>
                            {dataItem.emailCount.toLocaleString()}
                          </Grid>
                          <Grid className={viewByDomainClasses.lightCell}>
                            {tableFormatMessage(
                              logicConfig.isFail ? 'percentage_of_failures' : 'percentage_of_passes',
                              {
                                count: logicConfig.getDmarcPercentage(dataItem)
                              }
                            )}
                          </Grid>
                        </CellText>
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    width={150}
                    field={logicConfig.tableConfig.columns.SPF_STATUS}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.SPF_STATUS]}
                    title={tableFormatMessage(`${logicConfig.tableConfig.columns.SPF_STATUS}`)}
                    cell={({ dataItem }: { dataItem: DmarcSubdomain }) => (
                      <Cell className={viewByDomainClasses.cell} data-field="table-column-spf-status">
                        <DmarcAuthAnalysisCell
                          type={AnalysisCellTypes.spf}
                          analyzedData={investigateDmarcAnalysisLogic.getSpfSubdomainAnalysis(dataItem)}
                        />
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    field={logicConfig.tableConfig.columns.SPF_DOMAIN}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.SPF_DOMAIN]}
                    title={tableFormatMessage(`${logicConfig.tableConfig.columns.SPF_DOMAIN}`)}
                    cell={({ dataItem }: { dataItem: DmarcSubdomain }) => {
                      const analyzedSpfDomains = investigateDmarcAnalysisLogic.getSpfSubdomainAnalysis(dataItem)

                      return (
                        <Cell className={viewByDomainClasses.cell} data-field="table-column-spf-domain">
                          {analyzedSpfDomains.map((analyzedData, index) => (
                            <Grid
                              key={uuidv4()}
                              className={
                                index === analyzedSpfDomains.length - 1
                                  ? undefined
                                  : viewByDomainClasses.domainWithMargin
                              }
                            >
                              {analyzedData.name}
                            </Grid>
                          ))}
                        </Cell>
                      )
                    }}
                  />

                  <DataTableColumn
                    width={150}
                    field={logicConfig.tableConfig.columns.DKIM_STATUS}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DKIM_STATUS]}
                    title={tableFormatMessage(`${logicConfig.tableConfig.columns.DKIM_STATUS}`)}
                    cell={({ dataItem }: { dataItem: DmarcSubdomain }) => (
                      <Cell className={viewByDomainClasses.cell} data-field="table-column-dkim-status">
                        <DmarcAuthAnalysisCell
                          type={AnalysisCellTypes.dkim}
                          analyzedData={investigateDmarcAnalysisLogic.getDkimSubdomainAnalysis(dataItem)}
                        />
                      </Cell>
                    )}
                  />

                  <DataTableColumn
                    field={logicConfig.tableConfig.columns.DKIM_DOMAIN}
                    {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DKIM_DOMAIN]}
                    title={tableFormatMessage(`${logicConfig.tableConfig.columns.DKIM_DOMAIN}`)}
                    cell={({ dataItem }: { dataItem: DmarcSubdomain }) => {
                      const analyzedDkimDomains = investigateDmarcAnalysisLogic.getDkimSubdomainAnalysis(dataItem)

                      return (
                        <Cell className={viewByDomainClasses.cell} data-field="table-column-dkim-domain">
                          {analyzedDkimDomains.map((analyzedData, index) => (
                            <Grid
                              key={uuidv4()}
                              className={
                                index === analyzedDkimDomains.length - 1
                                  ? undefined
                                  : viewByDomainClasses.domainWithMargin
                              }
                            >
                              {analyzedData.name}
                            </Grid>
                          ))}
                        </Cell>
                      )
                    }}
                  />

                  {logicConfig.isFail &&
                    ((
                      <DataTableColumn
                        field={logicConfig.tableConfig.columns.ACTIONS}
                        {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.ACTIONS]}
                        title={' '}
                        cell={({ dataItem }: { dataItem: DmarcSubdomain }) => (
                          <Cell className={viewByDomainClasses.cell} data-field="table-column-actions">
                            <DmarcRecommendationCell subdomain={dataItem} dmarcSource={dmarcSource} />
                          </Cell>
                        )}
                      />
                    ) as any)}
                </DataTable>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </>
    ),
    [
      dmarcSource,
      classes,
      formatMessage,
      onClose,
      viewByDomainClasses,
      logicConfig,
      tableFormatMessage,
      investigateDmarcAnalysisLogic
    ]
  )
}

export default ViewBySendingdomain
