// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo, useCallback } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import { DmarcSubdomain, DmarcSource } from 'global/types/api/dmarc'

import styles from 'sen/components/lib/dialogs/investigateDmarc/views/lib/dmarcRecommendationCellStyles'
import useDmarcRecommendationCellLogic, {
  LinkTypes
} from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useDmarcRecommendationCellLogic'

const BASE_I18N_KEY = 'sen.app.data_tables.view_by_domain_table'

export interface DmarcRecommendationCellProps {
  subdomain: DmarcSubdomain
  dmarcSource: DmarcSource
}

export const DmarcRecommendationCell: React.FC<DmarcRecommendationCellProps> = ({ subdomain, dmarcSource }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [logicConfig, eventHandlers] = useDmarcRecommendationCellLogic({ subdomain, dmarcSource })

  const renderAction = useCallback(
    (onClick: () => void, message: string, className?: string) => (
      <data className={className} role="presentation" onClick={onClick}>
        {formatMessage(message)}
      </data>
    ),
    [formatMessage]
  )

  return useMemo(() => {
    const linkType = logicConfig.getLinkTypeForRecommendation()

    if (!linkType) {
      return null
    }

    return (
      <Grid container className={classes.linkText} direction="column">
        {linkType === LinkTypes.spfAndDkim &&
          logicConfig.isSameDkimAndSpfLinks &&
          renderAction(eventHandlers.onOpenDkimDocLink, 'spf_and_dkim_setup')}

        {linkType === LinkTypes.spfAndDkim && !logicConfig.isSameDkimAndSpfLinks && (
          <>
            {renderAction(eventHandlers.onOpenDkimDocLink, 'dkim_setup')}
            {renderAction(eventHandlers.onOpenSpfDocLink, 'spf_setup', classes.textWithMargin)}
          </>
        )}

        {linkType === LinkTypes.spfOnly && renderAction(eventHandlers.onOpenSpfDocLink, 'spf_setup')}

        {linkType === LinkTypes.dkimOnly && renderAction(eventHandlers.onOpenDkimDocLink, 'dkim_setup')}

        {linkType === LinkTypes.alignmentOnly &&
          renderAction(eventHandlers.onOpenAlignmentDocLink, 'see_recommendation')}
      </Grid>
    )
  }, [classes, logicConfig, eventHandlers, renderAction])
}

export default DmarcRecommendationCell
