// TODO: Remove this file as part of BNFIR-3518
import { useEffect, useMemo, useCallback, useState } from 'react'

import { getErrorMessage } from 'global/redux/toolkit/api'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isDmarcFailed } from 'global/lib/domain/dmarc'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  getDmarcFailedSubdomains,
  getDmarcPassedSubdomains,
  resetDmarcSubdomains,
  resetRufSamples
} from 'sen/redux/features/dmarc/dmarcSlice'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useDmarcAnalyticsLogic from 'sen/lib/dmarc/useDmarcAnalyticsLogic'

export default function useInvestigateDialogLogic({
  onClose,
  dmarcSource,
  domain
}: T.InvestigateDmarcDialogProps): [T.LogicConfig, T.EventHandlers] {
  const { isLoaded, dmarcFailedDomainsError, dmarcPassedDomainsError, rufSamplesError } = useAppSelector(_stores => ({
    isLoaded: !!_stores.dmarc.dmarcFailedDomains && !!_stores.dmarc.dmarcPassedDomains,
    dmarcFailedDomainsError: getErrorMessage(_stores.dmarc.getDmarcFailedSubdomainsApiStatus),
    dmarcPassedDomainsError: getErrorMessage(_stores.dmarc.getDmarcPassedSubdomainsApiStatus),
    rufSamplesError: getErrorMessage(_stores.dmarc.getRufSamplesApiStatus)
  }))
  const dispatch = useAppDispatch()
  const dmarcAnalyticsLogic = useDmarcAnalyticsLogic()

  const [selectedTab, setSelectedTab] = useState<T.Tabs>(isDmarcFailed(dmarcSource) ? T.Tabs.fail : T.Tabs.pass)
  const [selectedView, setSelectedView] = useState<T.Views>(T.Views.viewBySendingdomain)
  const [previousView, setPreviousView] = useState<T.Views>(T.Views.viewBySendingdomain)

  // init
  useEffect(() => {
    dispatch(getDmarcFailedSubdomains({ domain: domain.name, host: dmarcSource.host }))
    dispatch(getDmarcPassedSubdomains({ domain: domain.name, host: dmarcSource.host }))

    return () => {
      dispatch(resetDmarcSubdomains())
      dispatch(resetRufSamples())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeTab = useCallback((_, newTab: T.Tabs) => {
    setSelectedTab(newTab)
  }, [])

  const onChangeView = useCallback((newView: T.Views) => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CHANGE_DIALOG_MODE, {
      newMode: newView
    })
    setSelectedView(newView)
    if ([T.Views.viewBySendingdomain, T.Views.viewBySendingips].includes(newView)) {
      setPreviousView(newView)
    }
  }, [])

  const onChangeViewBack = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CHANGE_BACK)
    setSelectedView(previousView)
  }, [previousView])

  const onCloseDialog = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CLOSE)

    onClose()
  }, [onClose])

  const tabPercentages = useMemo(() => {
    return [
      dmarcAnalyticsLogic.percentageLabel(dmarcSource.dmarcFailRatio),
      dmarcAnalyticsLogic.percentageLabel(dmarcAnalyticsLogic.dmarcPassRatio(dmarcSource))
    ]
  }, [dmarcAnalyticsLogic, dmarcSource])

  return useMemo(() => {
    return [
      {
        isLoaded,
        error: dmarcFailedDomainsError || dmarcPassedDomainsError || rufSamplesError,
        selectedView,
        selectedTab,
        tabPercentages
      },
      {
        onChangeTab,
        onChangeView,
        onChangeViewBack,
        onCloseDialog
      }
    ]
  }, [
    isLoaded,
    dmarcFailedDomainsError,
    dmarcPassedDomainsError,
    rufSamplesError,
    onChangeTab,
    selectedView,
    selectedTab,
    onChangeView,
    onChangeViewBack,
    onCloseDialog,
    tabPercentages
  ])
}
