// TODO: we may not need this file, delete if testing passes
import React from 'react'

import AppAlert from 'global/components/lib/appAlert/AppAlert'

export interface ContentLayoutProps {
  children?: any
}

export default function ContentLayout({ children }: ContentLayoutProps): JSX.Element {
  return (
    <>
      <AppAlert />
      {children}
    </>
  )
}
