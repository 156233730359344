import globalMessages, {
  BACK,
  BARRACUDA_EMAIL_PROTECTION,
  CANCEL,
  CLOSE,
  DATE,
  DFP_APP_NAME,
  LINKING_CODE_INVALID,
  MICROSOFT_365,
  NEXT,
  SERIAL_NUMBER_INVALID,
  SIGN_IN,
  START_TRIAL,
  SUBJECT
} from 'global/lib/i18n/en'

export const CHECK_DMARC_AGAIN = 'Check DMARC again'
export const CHECK_MY_SPF = 'Check my SPF'
export const COPIED = 'Copied'
const DISMISS = 'Dismiss'
export const PERMISSION_DIALOG_TITLE = `Why are so many permissions needed to run ${DFP_APP_NAME}?`

export default {
  ...globalMessages,
  dfp: {
    add_domain_dialog: {
      init: {
        title: 'New domain',
        input_tooltip: 'Domain name, e.g. acme.com',
        input_error: 'A valid domain name is required',
        subtitle_1: 'Enter the domain you would like to add',
        subtitle_2:
          'You will need to verify ownership of the domain by adding a TXT record on your domain host service.'
      },
      verification_instructions: {
        subtitle_1: 'Please verify you own the domain',
        subtitle_2:
          '<b>Please note:</b> adding this entry will only verify that you own this domain. It is not a DMARC record for it.'
      },
      verification_failure: {
        // eslint-disable-next-line quotes
        subtitle_1: "We couldn't find the verification record",
        subtitle_2: '<b>Troubleshooting</b>',
        subtitle_3: 'Have you saved your configuration on your domain host service?',
        subtitle_4: 'DNS changes may require 1-2 minutes to take effect, please try again shortly.'
      },
      verification_success: {
        subtitle_1: 'You are all set!',
        subtitle_2: `Your domain is verified and you can now deploy DMARC on it using ${DFP_APP_NAME}.`,
        // eslint-disable-next-line quotes
        subtitle_3: "Please click 'Setup DMARC' to start monitoring your domain."
      },
      domain_values: {
        title: 'Create a new record on the domain',
        domain_name: 'Domain name',
        type: 'Type',
        value: 'Value',
        copied: 'Copied to clipboard'
      },
      cancel: CANCEL,
      next: NEXT,
      setup_dmarc: 'Setup DMARC'
    },
    data_tables: {
      domains: {
        empty_table: 'No domains found',
        domain: 'Domain',
        status: 'Status',
        unknown_sources: 'Unknown sources',
        unknown_sources_tooltip: 'Unknown sources sending high volume of emails impersonating your domain',
        failures: 'Failures',
        failures_tooltip: 'Emails that failed DMARC from approved sources',
        enforce_dmarc: 'Enforce DMARC',
        delete_tooltip: `Domain must be removed through ${MICROSOFT_365}`,
        actions: {
          setup_dmarc_reporting: 'Set up DMARC reporting',
          verify_domain: 'Verify domain',
          waiting_for_sufficient_report: 'Waiting for sufficient reports',
          review_high_vol_sources: 'Review high volume sources',
          review_failures: 'Review failures',
          view_report: 'View DMARC reporting data'
        }
      },
      ruf_samples_table: {
        empty_table: 'No samples found',
        date: DATE,
        subject: SUBJECT,
        origin_country: 'Origin country',
        number_of_emails: 'Number of emails'
      },
      view_by_domain_table: {
        empty_table: 'No domains found',
        failed_reports: 'Failed reports',
        passed_reports: 'Passed reports',
        spf_status: 'SPF status',
        spf_domain: 'SPF domain',
        dkim_status: 'DKIM status',
        dkim_domain: 'DKIM domain',
        percentage_of_passes: '{count}% of passes',
        percentage_of_failures: '{count}% of failures',
        aligned: 'Aligned',
        misaligned: 'Misaligned',
        passed_spf: 'Passed SPF',
        failed_spf: 'Failed SPF',
        passed_dkim: 'Passed DKIM',
        failed_dkim: 'Failed DKIM',
        passed: 'Passed',
        failed: 'Failed',
        spf_and_dkim_setup: 'SPF and DKIM setup',
        dkim_setup: 'DKIM setup',
        spf_setup: 'SPF setup',
        see_recommendation: 'See recommendation'
      },
      view_by_ips_table: {
        empty_table: 'No IPs found',
        ip_address: 'IP address',
        country: 'Country',
        failed_reports: 'Failed reports',
        passed_reports: 'Passed reports',
        number_of_failures: 'Number of failures (Misalignment %)<br>Last 30 days</br>'
      }
    },
    delete_domain_dialog: {
      // eslint-disable-next-line quotes
      title: `Delete {domainName}`,
      init_subtitle_1: `Deleting this domain removes your ability to manage its DMARC through ${DFP_APP_NAME}. This action will not, however, remove associated DNS entries from your provider.`,
      init_subtitle_2: `For complete disengagement, after you remove the domain from ${DFP_APP_NAME}, go to your DNS provider and remove related entries.`,
      // eslint-disable-next-line quotes
      deleted_subtitle_1: `Deleted {domainName} domain.`,
      deleted_subtitle_2: 'Visit your DNS provider to remove related entries.',
      cancel: CANCEL,
      dismiss: DISMISS,
      delete_this_domain: 'Delete this domain'
    },
    dmarc_countries_dialog: {
      map_title: 'Countries sending mail impersonating your domain',
      map_subtitle: 'Last 30 days',
      // eslint-disable-next-line quotes
      table_title: `Email samples for {countryName}`,
      close: 'Close',
      back: 'Back',
      dmarc_countries_map: {
        emails_from_blacklist: 'Emails from blacklisted IPs',
        total_emails: 'Total emails'
      },
      dmarc_fraud_table: {
        date: 'Date',
        subject: 'Subject',
        origin: 'Origin country',
        emails: 'Number of emails',
        empty_table: 'No data available',
        view_action: 'view'
      },
      dmarc_fraud_sample: {
        to: 'To',
        from: 'From',
        reply: 'Reply to',
        date: 'Date',
        subject: 'Subject'
      }
    },
    domain_fraud: {
      title: 'Domains',
      subtitle: 'Gather intelligence and protect your domain identity with DMARC.',
      ready_to_enforce: {
        title: 'Next step: enforcing DMARC',
        subtitle_1:
          'Based on your efforts, the following domains meet the criteria for DMARC enforcement (no unknown high volume senders and minimal DMARC failures):',
        subtitle_2: 'For each domain, click Enforce DMARC to take the next step in the protection process.'
      },
      add_domain_button: 'Add domain for DMARC reporting'
    },
    domain_wizard_dialog: {
      configure_content: {
        spf_subtitle_1: 'We were unable to find an SPF record on your domain. To set one up:',
        // eslint-disable-next-line quotes
        dmarc_subtitle_1: "Here's how to create it:",
        step_1:
          'Sign in to your domain host service (e.g. GoDaddy). Not sure which service you use? Check the Registrar section <link>here</link>',
        spf_step_2: 'Create a new record with this value:',
        dmarc_step_2: 'Create a new record for the <b>_dmarc</b> subdomain:',
        name: 'Name',
        type: 'Type',
        value: 'Value',
        subtitle_2: `<b>Important:</b> if you use a hybrid on-prem/online deployment of ${MICROSOFT_365}, please refer to <link>this Microsoft article</link> to configure your SPF records correctly.`
      },
      init: {
        // eslint-disable-next-line quotes
        title: "Let's start by checking your SPF record",
        subtitle_1:
          'SPF (Sender Policy Framework) allows email recipients to verify that emails from your domain are received from authorized email servers only. SPF is implemented as a DNS record of type TXT on {domainName}.',
        subtitle_2: 'We will now verify that your SPF record is valid.',
        cancel: CANCEL,
        check_my_spf: CHECK_MY_SPF
      },
      spf_check: {
        title: 'Checking your SPF record... This may take up to one minute',
        cancel: CANCEL
      },
      spf_check_failure: {
        title: 'Please configure your SPF record',
        cancel: CANCEL,
        check_my_spf: CHECK_MY_SPF
      },
      spf_check_success: {
        title: 'Your SPF is configured! You can now configure DMARC',
        subtitle_1:
          'DMARC will tell email recipients to send back a report whenever an email from your domain fails authentication. We will automatically process these reports to detect fraud attempts and/or issues with your email authentication configuration.',
        subtitle_2: 'We will now prepare our system to receive reports on your behalf.',
        spf_record: 'Your SPF record',
        copied: COPIED,
        cancel: CANCEL,
        configure_dmarc: 'Configure DMARC'
      },
      dmarc_prep: {
        title: 'Preparing to receive reports...',
        cancel: CANCEL
      },
      dmarc_setup: {
        title: 'Please configure your DMARC record',
        cancel: CANCEL,
        check_my_dmarc: 'Check my DMARC'
      },
      dmarc_check: {
        title: 'Checking your DMARC record... This may take up to one minute',
        cancel: CANCEL
      },
      dmarc_invalid: {
        title: 'Your DMARC record is invalid',
        subtitle_1: 'Please use this exact record:',
        subtitle_2: 'Your current record:',
        cancel: CANCEL,
        check_dmarc_again: CHECK_DMARC_AGAIN
      },
      dmarc_not_found: {
        // eslint-disable-next-line quotes
        title: "We couldn't find a DMARC record on your domain",
        troubleshooting_title: '<b>Troubleshooting:</b>',
        troubleshooting_subtitle_1: 'Have you added the entry on {dmarcRecord} (as opposed to {domainName})?',
        troubleshooting_subtitle_2: 'Have you saved your configuration on your domain host service?',
        troubleshooting_subtitle_3:
          'Please note that in certain cases, DNS changes take up to 48 hours to propagate throughout the Internet. Please revisit this wizard later and check again.',
        troubleshooting_subtitle_4: 'DNS changes may require 1-2 minutes to take effect, please try again shortly.',
        cancel: CANCEL,
        check_dmarc_again: CHECK_DMARC_AGAIN
      },
      dmarc_check_success: {
        title: 'You are all set!',
        subtitle_1:
          'We will collect DMARC reports on your behalf and get back to you with insights shortly. Please feel free to chat with us at any time if you have questions.',
        subtitle_2:
          // eslint-disable-next-line quotes
          "Once we verify that your domain's authentication is configured correctly, we will help you turn on DMARC enforcement, preventing hackers from sending fraudulent emails on your behalf.",
        finish: 'Finish'
      }
    },
    enforce_dmarc_dialog: {
      enforcement_title: 'Enable DMARC enforcement',
      enforcement_subtitle_1:
        'Copy this DNS record and use with your DNS provider to instruct email servers to reject all emails from your domain that fail DMARC. Some DNS providers require records be added in a particular way. Refer to your provider for the exact directions.',
      enforcement_subtitle_2:
        'NOTE: Instructions in DNS records will only be enforced by email servers that are observing DMARC.',
      reporting_title: 'Enable DMARC reporting only',
      reporting_subtitle_1:
        'Use this record with your DNS provider to begin collecting data from DMARC reporting. Some DNS providers require records be added in a particular way. Refer to your provider for the exact directions.',
      reporting_subtitle_2:
        'NOTE: Instructions in DNS records will only be enforced by email servers that are observing DMARC.',
      copy_dns_record: 'Copy DNS record',
      dmarc_check_title: 'Your DMARC record',
      dmarc_check_subtitle_1: 'Checking DMARC record...',
      dmarc_check_subtitle_2: 'This process could take up to a minute.',
      result_title: 'Your DMARC record',
      success_enforcement_title: 'Enforcement enabled',
      success_enforcement_subtitle_1:
        'All emails that fail DMARC will be rejected by organizations that are choosing to observe DMARC.',
      success_enforcement_subtitle_2:
        'You should regularly monitor your DMARC reports to make sure you are aware of unknown sources and make sure that your approved sources are still passing DMARC.',
      success_reporting_title: 'Reporting only enabled',
      success_reporting_subtitle_1:
        'DMARC for you domain is set to Reporting Only. Sources sending email as your domain will be allowed to go through.',
      success_reporting_subtitle_2:
        'Reporting is used to gather intelligence around what sources are sending email as your domain.',
      failed_enforcement_title: 'DMARC enforcement record not found',
      failed_enforcement_subtitle_1:
        'We cannot detect a valid enforcement record for your domain. This could be due to an incorrect DNS entry.',
      failed_enforcement_subtitle_2: 'Please double check your DNS entry and guidelines with your provider.',
      failed_reporting_title: 'DMARC reporting record not found',
      failed_reporting_subtitle_1:
        'We cannot detect a valid reporting record for your domain. This could be due to an incorrect DNS entry.',
      failed_reporting_subtitle_2: 'Please double check your DNS entry and guidelines with your provider.',
      check_dmarc_record: 'Check DMARC record',
      back: BACK,
      close: CLOSE,
      cancel: CANCEL
    },
    investigate_dmarc_dialog: {
      title: '{count} DMARC reports for {host}',
      view_failure_samples: 'View failure samples',
      no_failure_samples: 'No failure samples',
      view_by_sending_ips: 'View by sending IPs',
      view_by_sending_domain: 'View by sending domain',
      close: CLOSE,
      back: BACK,
      view: 'View',
      fail: 'Fail',
      pass: 'Pass'
    },
    login: {
      barracuda_product: DFP_APP_NAME,
      product_blurb: 'Stop brand impersonation attacks on your customers and business partners',
      signup_description: `${DFP_APP_NAME} protects your brands from spoofing and impersonation, simplifies email authentication, and improves deliverability of your marketing messages`,
      form: {
        already_using: `Already using ${DFP_APP_NAME}? <signin>${SIGN_IN}</signin>`,
        permission_dialog_title: PERMISSION_DIALOG_TITLE
      }
    },
    missing_requirements: {
      page_title: 'Missing requirements',
      entitlements: {
        subtitle: `You do not currently have permissions to access the Domain Fraud Protection dashboard for this ${MICROSOFT_365} tenant. Please reach out to your Barracuda account admin to add the necessary entitlements`
      },
      card_title: DFP_APP_NAME
    },
    navbar: {
      title: DFP_APP_NAME,
      options: {
        apps: 'Your apps',
        profile: 'Profile',
        profileMenu: {
          manageAccount: 'Manage account',
          logOut: 'Log out'
        }
      }
    },
    signin_connect: {
      app_name: DFP_APP_NAME,
      title: `Connect to ${MICROSOFT_365} to import your domains into ${DFP_APP_NAME}`,
      permission_dialog_title: PERMISSION_DIALOG_TITLE,
      subtitle_1: `Secure your account in real-time with ${DFP_APP_NAME}. You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      subtitle_2:
        'After you sign in to your Microsoft account, accept the application <perm>permissions</perm> to continue.',
      o365_button: `Connect to ${MICROSOFT_365}`,
      footer_text: 'Use another account',
      account_want_to_connect: 'Account to connect',
      skip_button: 'Continue and complete this step later'
    },
    start_trial: {
      activate: {
        activate_button: `Activate ${DFP_APP_NAME}`,
        activate_header: `Activate ${DFP_APP_NAME}`,
        linking_code: 'Linking code',
        linking_code_error: LINKING_CODE_INVALID,
        serial_number: 'Serial number',
        serial_number_error: SERIAL_NUMBER_INVALID
      },
      already_purchased: `I have already purchased ${DFP_APP_NAME}`,
      barracuda: BARRACUDA_EMAIL_PROTECTION,
      product: DFP_APP_NAME,
      subtitle: 'Protect your brands from spoofing and impersonation.',
      product_description:
        'Use Barracuda DMARC reporting to protect your reputation and stop domain fraud that targets your customers and business partners.',
      try_free: `Try <b>${DFP_APP_NAME}</b> trial for FREE for 14-days.`,
      no_credit_card: 'No credit card is required.',
      start_trial: START_TRIAL,
      use_another_account: 'Use another account'
    }
  }
}
