import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const CARD_HEIGHT = 320
export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT
export const INBOX_FEED_CELL_HEIGHT = 81

export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  return {
    container: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      lineHeight: '10px',
      color: COLORS.GRAY
    },

    ...defaultTableStyles,
    tableCard: {
      position: 'relative',
      minHeight: CARD_HEIGHT,
      padding: 0
    },
    tableCircularProgress: {
      paddingTop: theme.spacing(10)
    },
    tableWrapper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      paddingTop: 0
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    flexibleDataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    deleteIcon: {
      cursor: 'pointer',
      color: COLORS.RED_600
    },
    disabledDeleteIcon: {
      cursor: 'default',
      color: COLORS.DISABLED_PLACEHOLDER_TEXT
    },
    HelpOutlineIconWrapper: {
      display: 'inline-block',
      width: 15,
      height: 15,
      verticalAlign: -3
    },
    HelpOutlineIcon: {
      width: 15,
      height: 15
    },
    error: {
      marginBottom: theme.spacing(2)
    },

    readyToEnforcePaper: {
      position: 'relative',
      padding: theme.spacing(2),
      fontSize: 12,
      backgroundColor: '#EBEEF5'
    },
    readyToEnforceTitle: {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: theme.spacing(2)
    },
    readyToEnforceSubtitle: {
      fontSize: 16,
      lineHeight: '18px',
      marginBottom: theme.spacing(1)
    },
    closeReadyToEnforce: {
      cursor: 'pointer',
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2)
    },
    enforceDmarc: {
      margin: `${theme.spacing(1)}px 0`
    },
    addDomainButton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3)
    }
  }
})
