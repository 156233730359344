// TODO: Remove this file as part of BNFIR-3518
/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'

import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  DataTable,
  Typography,
  DataTableColumn
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import { DmarcIp, DmarcThreats } from 'global/types/api/dmarc'
import { getCountryName } from 'global/lib/isoCountries'
import { BASE_IMG_PATH } from 'global/configs/theme/images.config'

import styles from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogStyles'
import viewByIpsStyles from 'sen/components/lib/dialogs/investigateDmarc/views/viewByIpStyles'
import useViewByIpsLogic from 'sen/components/lib/dialogs/investigateDmarc/views/useViewByIpsLogic'
import * as T from 'sen/components/lib/dialogs/investigateDmarc/investigateDmarcDialogTypes'
import useInvestigateDmarcAnalysisLogic from 'sen/components/lib/dialogs/investigateDmarc/views/lib/useInvestigateDmarcAnalysis'

const BASE_I18N_KEY = 'sen.app.investigate_dmarc_dialog'
const BASE_I18N_TABLE_KEY = 'sen.app.data_tables.view_by_ips_table'

export const ViewBySendingIps: React.FC<T.ViewProps> = ({ selectedTab, domain, dmarcSource, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const viewByIpsClasses = viewByIpsStyles()
  const [logicConfig] = useViewByIpsLogic({ selectedTab, domain, dmarcSource })
  const [investigateDmarcAnalysisLogic] = useInvestigateDmarcAnalysisLogic()

  return useMemo(
    () => (
      <>
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            direction="column"
            className={viewByIpsClasses.tableWrapper}
            data-field="domains-table-wrapper"
          >
            <Grid>
              <Typography className={viewByIpsClasses.headerAdditionalText}>
                {tableFormatMessage('number_of_failures', {
                  br: (txt: string) => (
                    <data key={txt}>
                      <br />
                      {txt}
                    </data>
                  )
                })}
              </Typography>
            </Grid>
            <Grid
              className={`${
                logicConfig.tableConfig.isFlexibleTable
                  ? viewByIpsClasses.flexibleTableArea
                  : viewByIpsClasses.tableArea
              } ${!logicConfig.tableConfig.tableData.total ? 'empty-table' : ''}`}
              data-table="view-by-ips"
              item
              xs={12}
              data-field="grid-table"
            >
              <DataTable
                data-testid="view-by-ids-table"
                className={`${
                  logicConfig.tableConfig.tableData.total
                    ? logicConfig.tableConfig.isFlexibleTable
                      ? viewByIpsClasses.flexibleDataTable
                      : viewByIpsClasses.dataTable
                    : viewByIpsClasses.emptyDataTable
                } alerts-table`}
                data={logicConfig.tableConfig.tableData}
                {...logicConfig.tableConfig.pageConfig}
                pager={Pager}
              >
                <DataTableNoRecords data-field="empty-table">
                  <Typography>
                    {!logicConfig.tableConfig.tableData.total && tableFormatMessage('empty_table')}
                  </Typography>
                </DataTableNoRecords>

                <DataTableColumn
                  field={logicConfig.tableConfig.columns.IP_ADDRESS}
                  {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.IP_ADDRESS]}
                  title={tableFormatMessage(`${logicConfig.tableConfig.columns.IP_ADDRESS}`)}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => (
                    <Cell className={viewByIpsClasses.cell} data-field="table-column-ip-address">
                      <CellText className={viewByIpsClasses.normalCell} data-field="table-column-ip-address-text">
                        {dataItem.ip}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  width={220}
                  field={logicConfig.tableConfig.columns.COUNTRY}
                  {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.COUNTRY]}
                  title={tableFormatMessage(`${logicConfig.tableConfig.columns.COUNTRY}`)}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => (
                    <Cell className={viewByIpsClasses.cell} data-field="table-column-country">
                      <CellText className={viewByIpsClasses.normalCell} data-field="table-column-country-text">
                        <img
                          src={`${BASE_IMG_PATH}flags/${dataItem.country.toLowerCase()}.svg`}
                          width="10"
                          height="10"
                          alt=""
                        />
                        &nbsp;&nbsp;
                        {getCountryName(dataItem.country)}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  field={logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']}
                  {...logicConfig.tableConfig.columnsConfig[
                    logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']
                  ]}
                  title={tableFormatMessage(
                    `${logicConfig.tableConfig.columns[logicConfig.isFail ? 'FAILED_REPORTS' : 'PASSED_REPORTS']}`
                  )}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => (
                    <Cell className={viewByIpsClasses.cell} data-field="table-column-passed-reports">
                      <CellText
                        ellipsis
                        className={viewByIpsClasses.normalCell}
                        data-field="table-column-passed-reports-text"
                      >
                        {dataItem.emailCount.toLocaleString()}
                      </CellText>
                    </Cell>
                  )}
                />

                <DataTableColumn
                  width={150}
                  headerClassName={viewByIpsClasses.headerGreyCell}
                  field={logicConfig.tableConfig.columns.DMARC}
                  {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DMARC]}
                  title={DmarcThreats.dmarc}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => {
                    return (
                      <Cell className={viewByIpsClasses.greyCell} data-field="table-column-dmarc">
                        <CellText
                          ellipsis
                          className={
                            viewByIpsClasses[
                              investigateDmarcAnalysisLogic.isDmarcFailure(dataItem) ? 'failedText' : 'successText'
                            ]
                          }
                          data-field="table-column-dmarc-text"
                        >
                          {dataItem.dmarcFail.toLocaleString()}&nbsp;({dataItem.dmarcFailRatio}%)
                        </CellText>
                      </Cell>
                    )
                  }}
                />

                <DataTableColumn
                  width={150}
                  headerClassName={viewByIpsClasses.headerGreyCell}
                  field={logicConfig.tableConfig.columns.SPF}
                  {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.SPF]}
                  title={DmarcThreats.spf}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => {
                    return (
                      <Cell className={viewByIpsClasses.greyCell} data-field="table-column-spf">
                        <CellText
                          ellipsis
                          className={
                            viewByIpsClasses[
                              investigateDmarcAnalysisLogic.isSpfFailure(dataItem) ? 'failedText' : 'successText'
                            ]
                          }
                          data-field="table-column-spf-text"
                        >
                          {dataItem.spfFail.toLocaleString()}&nbsp;({dataItem.spfFailRatio}%)
                        </CellText>
                      </Cell>
                    )
                  }}
                />

                <DataTableColumn
                  width={150}
                  headerClassName={viewByIpsClasses.headerGreyCell}
                  field={logicConfig.tableConfig.columns.DKIM}
                  {...logicConfig.tableConfig.columnsConfig[logicConfig.tableConfig.columns.DKIM]}
                  title={DmarcThreats.dkim}
                  cell={({ dataItem }: { dataItem: DmarcIp }) => {
                    return (
                      <Cell className={viewByIpsClasses.greyCell} data-field="table-column-dkim">
                        <CellText
                          ellipsis
                          className={
                            viewByIpsClasses[
                              investigateDmarcAnalysisLogic.isDkimFailure(dataItem) ? 'failedText' : 'successText'
                            ]
                          }
                          data-field="table-column-dkim-text"
                        >
                          {dataItem.dkimFail.toLocaleString()}&nbsp;({dataItem.dkimFailRatio}%)
                        </CellText>
                      </Cell>
                    )
                  }}
                />
              </DataTable>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={onClose}>
            &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, onClose, investigateDmarcAnalysisLogic, logicConfig, tableFormatMessage, viewByIpsClasses]
  )
}

export default ViewBySendingIps
