// TODO: Remove this file as part of BNFIR-3518
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  const icon = createStyles({
    defaults: {
      marginBottom: -3,
      marginRight: theme.spacing(1)
    }
  })

  return {
    ...defaultDialogStyles,
    copyToClipboard: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    linearProgress: {
      marginBottom: theme.spacing(2)
    },
    dmarcRecord: {
      padding: theme.spacing(2),
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    resultTitle: {
      ...defaultDialogStyles.dialogTitle,
      marginBottom: theme.spacing(2)
    },
    successIcon: {
      ...icon.defaults,
      color: COLORS.TEAL_600
    },
    failedIcon: {
      ...icon.defaults,
      color: COLORS.RED_600
    }
  }
})
