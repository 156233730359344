import { useMemo, useCallback, useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isSuccess, isPending } from 'global/redux/toolkit/api'
import { DMARC_STATES, getDmarcState, getEnforcementRecord, getReportingRecord } from 'global/lib/domain/dmarc'
import { isTimedOut } from 'global/lib/datetime'

import { useAppDispatch, useAppSelector } from 'dfp/redux/toolkit/hooks'
import { resolveDmarc, resetResolvedDmarc } from 'dfp/redux/features/dmarc/dmarcSlice'
import {
  EnforcementSteps,
  EnforcementGoal,
  DialogConfig,
  DialogEvents,
  DmarcEnforcementWizardDialogProps
} from 'dfp/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'

const REFRESH_FREQ = 5000
let interval: any
let inProgress = false

export default function useEnforcementWizardDialogLogic({
  onClose,
  domain,
  goal
}: DmarcEnforcementWizardDialogProps): [DialogConfig, DialogEvents] {
  const { isDmarcInProgress, isDmarcDataLoaded, resolvedDmarc } = useAppSelector(_stores => ({
    isDmarcInProgress: isPending(_stores.dmarc.resolveDmarcApiStatus),
    isDmarcDataLoaded: isSuccess(_stores.dmarc.resolveDmarcApiStatus),
    resolvedDmarc: _stores.dmarc.resolvedDmarc
  }))
  const dispatch = useAppDispatch()
  const [wizardStep, setWizardStep] = useState<EnforcementSteps>(EnforcementSteps.init)
  const [checkUntil, setCheckUntil] = useState<DateTime | undefined>()
  const [isDomainModified, setDomainModified] = useState(false)

  // init
  useEffect(() => {
    return () => {
      setWizardStep(EnforcementSteps.init)
      dispatch(resetResolvedDmarc())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dmarcResultForRecord = useMemo(() => {
    if (!resolvedDmarc) {
      return EnforcementGoal.unknown
    }

    const dmarcState = getDmarcState(resolvedDmarc)
    switch (dmarcState[0]) {
      case DMARC_STATES.PROTECTED:
        return EnforcementGoal.enforcement
      case DMARC_STATES.UNPROTECTED:
        return EnforcementGoal.reporting
      default:
    }

    return EnforcementGoal.unknown
  }, [resolvedDmarc])

  useEffect(() => {
    const isDmarcRecordCorrespondsToGoal = dmarcResultForRecord === goal
    const isOvertimed = isTimedOut(checkUntil)

    inProgress = isDmarcInProgress

    if (isDmarcDataLoaded && (isDmarcRecordCorrespondsToGoal || isOvertimed)) {
      setWizardStep(EnforcementSteps.result)
      inProgress = false

      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isDmarcDataLoaded, checkUntil, resolvedDmarc, dmarcResultForRecord, isDmarcInProgress, goal])

  const dmarcRecord = useMemo(() => {
    return goal === EnforcementGoal.enforcement ? getEnforcementRecord(domain) : getReportingRecord(domain)
  }, [goal, domain])

  const onCloseDialog = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DMARC_ENFORCEMENT_CLOSE, { domain: domain.name })
    onClose(isDomainModified)
  }, [onClose, domain, isDomainModified])

  const onSetStepToInit = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DMARC_ENFORCEMENT_BACK_TO_INIT, { domain: domain.name })
    setWizardStep(EnforcementSteps.init)
    dispatch(resetResolvedDmarc())
  }, [dispatch, domain])

  const refreshDmarc = useCallback(() => {
    if (!inProgress) {
      dispatch(resolveDmarc(domain.name))
    }
  }, [dispatch, domain])

  const onCheckDmarc = useCallback(() => {
    setDomainModified(true)
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DMARC_ENFORCEMENT_CHECK, { domain: domain.name })
    inProgress = true
    dispatch(resolveDmarc(domain.name))
    setCheckUntil(DateTime.local().plus({ minutes: 1 }))
    setWizardStep(EnforcementSteps.dmarcCheck)
    interval = setInterval(refreshDmarc, REFRESH_FREQ)
  }, [domain, dispatch, refreshDmarc])

  return useMemo(() => {
    return [
      {
        step: wizardStep,
        domainName: domain.name,
        goal,
        dmarcResult: dmarcResultForRecord,
        inProgress,
        dmarcRecord
      },
      {
        onCloseDialog,
        onSetStepToInit,
        onCheckDmarc
      }
    ]
  }, [wizardStep, domain, onCloseDialog, onSetStepToInit, onCheckDmarc, dmarcRecord, goal, dmarcResultForRecord])
}
