// TODO: Remove this file as part of BNFIR-3518
import { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'

import { CompanyDomain } from 'global/types/api/dmarc'

import { isSuccess, isPending, getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { verifyDomain, addDomain, resetVerifiedDomain, resetAddDomain } from 'sen/redux/features/dmarc/dmarcSlice'
import {
  AddDomainWizardDialogLogicProps,
  DialogConfig,
  DialogEvents,
  WizardSteps
} from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogTypes'
import { isTimedOut } from 'global/lib/datetime'

const REFRESH_FREQ = 5000
let inProgress = false

export default function useAddDomainWizardDialogLogic({
  onClose,
  domain
}: AddDomainWizardDialogLogicProps): [DialogConfig, DialogEvents] {
  const {
    isAddDomainInProgress,
    isAddDomainSuccess,
    addedDomain,
    addDomainError,
    isVerifyDomainInProgress,
    isVerifyDomainLoaded,
    verifiedDomain,
    verifyDomainError
  } = useAppSelector(_stores => ({
    isAddDomainInProgress: isPending(_stores.dmarc.addDomainApiStatus),
    isAddDomainSuccess: isSuccess(_stores.dmarc.addDomainApiStatus),
    addedDomain: _stores.dmarc.addedDomain,
    addDomainError: getErrorMessage(_stores.dmarc.addDomainApiStatus),
    verifiedDomain: _stores.dmarc.verifiedDomain,
    isVerifyDomainInProgress: isPending(_stores.dmarc.verifyDomainApiStatus),
    isVerifyDomainLoaded: isSuccess(_stores.dmarc.verifyDomainApiStatus),
    verifyDomainError: getErrorMessage(_stores.dmarc.verifyDomainApiStatus)
  }))
  const dispatch = useAppDispatch()
  const [wizardStep, setWizardStep] = useState<WizardSteps>(WizardSteps.init)
  const [domainName, setDomainName] = useState<CompanyDomain['name']>('')
  const [isDomainNameChanged, setIsDomainNameChanged] = useState<boolean>(false)
  const [checkUntil, setCheckUntil] = useState<DateTime | undefined>()
  const interval = useRef<ReturnType<typeof setInterval> | null>()
  const [isDomainModified, setDomainModified] = useState(false)

  const clearPeriodicCheck = useCallback(() => {
    inProgress = false
    setCheckUntil(undefined)

    if (interval.current) {
      clearInterval(interval.current)
    }
  }, [])

  // init
  useEffect(() => {
    if (domain) {
      dispatch(verifyDomain(domain.name))
      setDomainName(domain.name)
      setWizardStep(WizardSteps.verificationInstructions)
    }

    return () => {
      clearPeriodicCheck()
      setWizardStep(WizardSteps.init)
      dispatch(resetVerifiedDomain())
      dispatch(resetAddDomain())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set to verificationInstructions step after new domain is added
  useEffect(() => {
    if (isAddDomainSuccess && wizardStep === WizardSteps.init) {
      setWizardStep(WizardSteps.verificationInstructions)
    }
  }, [isAddDomainSuccess, wizardStep])

  // set to failure step if the domain is not verified
  useEffect(() => {
    if (verifiedDomain && !verifiedDomain.verified && wizardStep === WizardSteps.verificationInstructions) {
      setWizardStep(WizardSteps.verificationFailure)
    }
  }, [verifiedDomain, wizardStep])

  const verifyDomainPeriodically = useCallback(() => {
    const domainToVerify = domain || addedDomain
    if (!inProgress && domainToVerify) {
      dispatch(verifyDomain(domainToVerify.name))
    }
  }, [dispatch, domain, addedDomain])

  // verifyDomain is finished
  useEffect(() => {
    const isOverTimed = isTimedOut(checkUntil)
    inProgress = isVerifyDomainInProgress
    if (isVerifyDomainLoaded && (verifiedDomain?.verified || isOverTimed)) {
      if (verifiedDomain?.verified) {
        setWizardStep(WizardSteps.verificationSuccess)
      } else if (isOverTimed) {
        setWizardStep(WizardSteps.verificationFailure)
      }

      clearPeriodicCheck()
    }
  }, [isVerifyDomainInProgress, checkUntil, isVerifyDomainLoaded, verifiedDomain, clearPeriodicCheck])

  // clear the periodic check on verifyDomain error
  useEffect(() => {
    if (verifyDomainError) {
      clearPeriodicCheck()
    }
  }, [verifyDomainError, clearPeriodicCheck])

  const onCloseDialog = useCallback(() => {
    onClose(!!addedDomain || !!verifiedDomain?.verified || isDomainModified)
  }, [addedDomain, verifiedDomain, onClose, isDomainModified])

  const onNextStep = useCallback(() => {
    setDomainModified(true)
    switch (wizardStep) {
      case WizardSteps.init:
        dispatch(addDomain(domainName))
        break
      case WizardSteps.verificationInstructions:
      case WizardSteps.verificationFailure:
        if (verifiedDomain) {
          dispatch(verifyDomain(verifiedDomain.name))
          setCheckUntil(DateTime.local().plus({ minutes: 1 }))
          inProgress = true
          interval.current = setInterval(verifyDomainPeriodically, REFRESH_FREQ)
        }
        break
      case WizardSteps.verificationSuccess:
        onCloseDialog()
        break
      default:
    }
  }, [wizardStep, dispatch, domainName, verifyDomainPeriodically, verifiedDomain, onCloseDialog])

  const onSetDomainName = useCallback((e: any) => {
    setIsDomainNameChanged(true)
    setDomainName(e.target.value)
  }, [])

  const stepperStep = useMemo(() => {
    switch (wizardStep) {
      case WizardSteps.init:
        return 0
      case WizardSteps.verificationInstructions:
      case WizardSteps.verificationFailure:
        return 1
      case WizardSteps.verificationSuccess:
        return 2
      default:
        return 0
    }
  }, [wizardStep])

  return useMemo(() => {
    return [
      {
        wizardStep,
        stepperStep,
        verifiedDomain,
        domainName,
        isDomainNameInputError: isDomainNameChanged && !domainName.length,
        isAddDomainInProgress,
        addDomainError,
        isVerifyDomainInProgress: isVerifyDomainInProgress || !!checkUntil,
        verifyDomainError
      },
      {
        onCloseDialog,
        onNextStep,
        onSetDomainName
      }
    ]
  }, [
    stepperStep,
    domainName,
    wizardStep,
    verifiedDomain,
    onNextStep,
    onSetDomainName,
    isDomainNameChanged,
    isAddDomainInProgress,
    addDomainError,
    isVerifyDomainInProgress,
    verifyDomainError,
    checkUntil,
    onCloseDialog
  ])
}
