// TODO: Remove this file as part of BNFIR-3518
import { CompanyDomain, DomainStatus } from 'global/types/api/dmarc'

export interface AddDomainWizardDialogLogicProps {
  onClose: (isDomainModified: boolean) => void
  domain?: CompanyDomain
}

export interface DialogConfig {
  wizardStep: WizardSteps
  stepperStep: number
  verifiedDomain: DomainStatus | undefined
  isVerifyDomainInProgress: boolean
  verifyDomainError: string | undefined
  domainName: CompanyDomain['name']
  isDomainNameInputError: boolean
  isAddDomainInProgress: boolean
  addDomainError: string | undefined
}

export interface DialogEvents {
  onCloseDialog: () => void
  onNextStep: () => void
  onSetDomainName: (e: any) => void
}

export enum WizardSteps {
  init,
  verificationInstructions,
  verificationFailure,
  verificationSuccess
}

export interface StepProps {
  config: DialogConfig
  events: DialogEvents
}
