// TODO: Remove this file as part of BNFIR-3518
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, DialogTitle, DialogContent, DialogActions } from '@barracuda-internal/bds-core'
import { Critical as CriticalIcon, Success as SuccessIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import { useFormatMessage } from 'global/lib/localization'

import styles from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogStyles'
import { StepProps } from 'sen/components/lib/dialogs/dmarcEnforcementWizard/dmarcEnforcementWizardDialogTypes'

const BASE_I18N_KEY = 'sen.app.enforce_dmarc_dialog'

export const DmarcEnforcementStepInit: React.FC<StepProps> = ({ config, events }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const isSuccess = config.goal === config.dmarcResult
  const localizationPrefix = `${isSuccess ? 'success' : 'failed'}_${config.goal}`

  return useMemo(
    () => (
      <>
        <DialogTitle data-step-name="result">
          <Typography className={classes.dialogTitle}>{formatMessage('result_title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.resultTitle}>
            {isSuccess && <SuccessIcon className={classes.successIcon} />}
            {!isSuccess && <CriticalIcon className={classes.failedIcon} />}
            {formatMessage(`${localizationPrefix}_title`)}
          </Typography>
          <Typography className={classes.dialogText}>{formatMessage(`${localizationPrefix}_subtitle_1`)}</Typography>
          <Typography className={classes.dialogText}>{formatMessage(`${localizationPrefix}_subtitle_2`)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" size="large" onClick={events.onCloseDialog}>
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </>
    ),
    [classes, formatMessage, events, isSuccess, localizationPrefix]
  )
}

DmarcEnforcementStepInit.propTypes = {
  config: PropTypes.any.isRequired,
  events: PropTypes.any.isRequired
}

export default DmarcEnforcementStepInit
